export const WorkspaceOverviewENUSTranslation = {
  workspaceOverviewTitle: 'Overview',
  workspaceOverviewDescription: 'Explore the results of your series.',
  workspaceOverviewResultsTitle: 'Results',
  workspaceOverviewResultsDescription:
    'Access your series and enable editing mode to apply adjustments in your projections.',
  workspaceOverviewResultsEnableEditMode: 'Enable  edit mode',
  workspaceOverviewResultsDiscardEdition: 'Discard',
  workspaceOverviewResultsSaveEdition: 'Save changes',
  workspaceOverviewResultsGroup: 'Group',
  workspaceOverviewResultsInvalidValue:
    'Please adjust your values to a number greater than zero.',
  workspaceOverviewResultsDownloadCurrentData: 'Export current view',
  workspaceOverviewResultsDownloadAllData: 'Export all data',
  workspaceOverviewResultsErrorTitle: 'Unable to load this content',
  workspaceOverviewResultsErrorDescription:
    'It may be that this table is under maintenance. Try again later.',
  workspaceOverviewResultsEditionLockedDescription:
    '<b>XXX</b> is currently editing this workspace, please come back later. To avoid losing your settings, it will remain in edit mode, but saving will only be possible when the user exits editing.',
  workspaceOverviewResultsLoadingData: 'Loading data...',
  workspaceOverviewResultsWorkspaceIsPublishing:
    'A new version of the workspace is being published. Please come back later.',
  workspaceOverviewResultsEditionBlockDownload:
    'To export, please exit the editing mode.',
  workspaceOverviewResultsEditionModeEnabledTitle: 'You are in editing mode',
  workspaceOverviewResultsEditionModeEnabledDescription:
    'Please close edit mode before updating data visualizations.',
  workspaceOverviewResultsErrorToSaveTitle: 'Error while saving',
  workspaceOverviewResultsErrorToSaveDescription:
    'An error occurred while trying to save the information of the series listed below. Try again later.',
  workspaceOverviewResultsType: 'Type',
  workspaceOverviewOthers: 'Others',
  workspaceOverviewResultsEditionDisabledTooltip:
    'Editing is not possible in preview mode.',

  workspaceOverviewResultsComparisonTitle: "Result's Comparison",
  workspaceOverviewResultsComparisonDescription:
    'Observe historical and forecast of selected series.',
  workspaceOverviewResultsComparisonSerieLabel: 'Serie',
  workspaceOverviewResultsComparisonSeriePlaceholder: 'Select a serie',
  workspaceOverviewResultsComparisonSelectFilterTitle:
    'Select one or more series',
  workspaceOverviewResultsComparisonSelectFilterDescription:
    'Use the inputs above to select the series you want to view or more than one you would like to compare.',
  workspaceOverviewResultsComparisonErrorTitle: 'Unable to load this content',
  workspaceOverviewResultsComparisonErrorDescription:
    'It may be that this chart is under maintenance. Try again later.',
  workspaceOverviewResultsComparisonSelectOnly4Series:
    'It is possible to select only XXX series',
  workspaceOverviewResultsComparisonEmptyDataDescription:
    'There is no data for the selected period.',

  workspaceOverviewPlanningFlowTitle: 'Planning Flow',
  workspaceOverviewPlanningFlowDescription:
    'Here are the main market impacts with the aggregation of all data. Explore different visualizations and gain the right insights for your issues.',
  workspaceOverviewPlanningFlowPreview: 'Preview',
  workspaceOverviewPlanningFlowAdjusting: 'Adjusting',
  workspaceOverviewPlanningFlowApproved: 'Approved',
  workspaceOverviewPlanningFlowStep: 'Stage ',
  workspaceOverviewPlanningFlowSubmitApproval: 'Submit for approval',
  workspaceOverviewPlanningFlowMessage: 'Message',
  workspaceOverviewPlanningFlowMessageDescription: 'Describe your changes',
  workspaceOverviewPlanningFlowRequestApproval: 'Request approval',
  workspaceOverviewPlanningFlowMessageDescriptionMaxCharactersError:
    'Must not be more than 300 characters',
  workspaceOverviewPlanningFlowMessageDescriptionMinCharactersError:
    'Must have more than 2 characters',
  workspaceOverviewPlanningFlowWaitingApproval: 'Waiting for approval',
  workspaceOverviewPlanningFlowReviewScenario: 'Review scenario',
  workspaceOverviewPlanningFlowNumberMaxOfStepsTooltip:
    'The maximum number of stages has been reached.',
  workspaceOverviewPlanningFlowApproveLastStepTooltip:
    'The last stage has not been approved yet.',
  workspaceOverviewPlanningFlowSeeMore: 'Show more',
  workspaceOverviewPlanningFlowSeeLess: 'Show less',
  workspaceOverviewPlanningFlowDisapprove: 'Disapprove',
  workspaceOverviewPlanningFlowApprove: 'Approve changes',
  workspaceOverviewPlanningFlowCreatingStep: 'Creating stage...',
  workspaceOverviewPlanningFlowDiscardAdjustments: 'Discard adjustments',
  workspaceOverviewPlanningFlowMakeAdjustments: 'Make adjustments',
  workspaceOverviewPlanningFlowDiscardingAdjustments:
    'Discarding adjustments...',
  workspaceOverviewPlanningFlowNoAdjustments: 'No adjustments',
  workspaceOverviewPlanningFlowUpdateErrorTitle:
    'Error updating the stage of the flow',
  workspaceOverviewPlanningFlowUpdateErrorDescription:
    'An error occurred while updating the stage of the flow. Please try again.',
  workspaceOverviewPlanningFlowIsRemoving:
    'Finalizing the removal of the last stage. Please wait.',

  workspaceOverviewApproveModalTitle: 'Do you want to approve the changes?',
  workspaceOverviewApproveModalDescription:
    'By confirming, the current values available to all users will be replaced with the adjusted ones.',

  workspaceOverviewDisapproveModalTitle: 'Do you want to revert the changes?',
  workspaceOverviewDisapproveModalDescription:
    'By confirming, the editors of this Workspace will receive the changes back, along with the message written below.',

  workspaceOverviewApproveOrDisapproveModalPlaceholder:
    'Write a feedback message',

  workspaceOverviewDiscardAdjustmentsConfirmationModalTitle:
    'Are you sure you want to discard all changes?',
  workspaceOverviewDiscardAdjustmentsConfirmationModalDescriptionFirstStep:
    'When you proceed, all changes made by you and others with access to this Workspace will be deleted and the preview will be restored.',
  workspaceOverviewDiscardAdjustmentsConfirmationModalDescriptionOtherStep:
    'When you proceed, all changes made by you and others with access to this Workspace will be deleted and the last approved version will be restored.',

  workspaceOverviewDeleteStepConfirmationModalTitle:
    'Are you sure you want to remove this stage?',
  workspaceOverviewDeleteStepConfirmationModalDescription:
    'All changes made will be permanently removed. If you wish to proceed, type the name of the stage in the field below.',

  workspaceOverviewLogsMessageError: 'Unable to load the change history.',
  workspaceOverviewLogsStage: 'Stage',
  workspaceOverviewLogsDiscardChanges: 'Discard changes',
  workspaceOverviewLogsTitle: 'Change History',
  workspaceOverviewLogsDescription:
    'See all changes made in this version of the Workspace.',

  workspaceOverviewLogsMade: 'Made',
  workspaceOverviewLogsChange: 'change',
  workspaceOverviewLogsChanges: 'changes',
  workspaceOverviewLogsApproved: 'Approved stage',
  workspaceOverviewLogsRejected: 'Rejected stage',
  workspaceOverviewLogsWaitingForApproval: 'Sent stage for approval',
  workspaceOverviewLogsShowMore: 'Show more',
  workspaceOverviewLogsShowLess: 'Show less',
  workspaceOverviewLogsStillNoChanges: 'Still no changes have occurred.',
  workspaceOverviewLogsButton: 'Change history',
  workspaceOverviewLogsCreatedNewStep: 'Created a new stage',
  workspaceOverviewLogsEditionStarted: 'Editing started',
  workspaceOverviewLogsDiscardedAdjustments: 'Discarded the adjustments',

  workspaceOverviewModalCreatedNewStageTitle: 'A new stage has been created',
  workspaceOverviewModalCreatedNewStageDescription:
    'Click below to access the most recent stage.',
  workspaceOverviewModalCreatedNewStageButton: 'Access the most recent stage',
  workspaceOverviewLogsRemovedStage: 'Removed stage',

  workspaceOverviewUpdateData: 'Update data',
  workspaceOverviewUpdateDataToEnableEditingMode:
    'Update the data to enable editing mode.',
  workspaceOverviewUpdateDataToEnableDiscardAdjustments:
    'Update the data to enable discard adjustments.',
  workspaceOverviewUpdateDataToEnableSubmitForApproval:
    'Update the data to enable submit for approval.',
  workspaceOverviewUpdateDataToEnableReviewScenario:
    'Update the data to enable review scenario.',
  workspaceOverviewResultsEditionBlockRemoveStage:
    'To remove, please exit the editing mode.',
  workspaceOverviewResultsReviewBlockRemoveStage:
    'To remove, please exit the review mode.',
};
