import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import {
  CellClassParams,
  ColDef,
  ColGroupDef,
  EditableCallbackParams,
  ExcelExportParams,
  ExcelStyle,
  IAggFuncParams,
  ICellRendererParams,
  IRowNode,
  ValueFormatterParams,
} from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { AxiosError } from 'axios';
import { addMonths, format, subMonths } from 'date-fns';
import enUS from 'date-fns/locale/en-US';
import ptBR from 'date-fns/locale/pt-BR';
import ms from 'ms';
import {
  ArrowsClockwise,
  CheckCircle,
  DownloadSimple,
  FloppyDisk,
  LockSimple,
  PaperPlaneTilt,
  PencilSimple,
  Trash,
  Warning,
  X,
} from 'phosphor-react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { Tooltip } from 'react-tooltip';
import { AgGridTable } from 'src/components/AgGridTable';
import { Button } from 'src/components/Button';
import { Card } from 'src/components/Card';
import { ContainerSkeleton } from 'src/components/ContainerSkeleton';
import { SelectedFilters } from 'src/components/SelectedFilters';
import { SelectedFilterOptions } from 'src/components/SelectedFilters/types';
import { Status } from 'src/components/Status';
import {
  Step,
  StepStatus,
  changeEditionModeEnabled,
  changeLastLogTime,
  changeReviewModeEnabled,
  changeWorkspaceOverviewForecast,
  changeWorkspaceOverviewPeriod,
  changeWorkspaceOverviewStep,
} from 'src/models/redux/reducers/WorkspaceOverviewOptions';
import { WorkspaceProjectionsForecastType } from 'src/models/redux/reducers/WorkspaceProjectionsOptions';
import apiWorkspace from 'src/models/service/apiWorkspace';
import { RootState } from 'src/redux/store';
import { queryClient } from 'src/service/queryClient';
import light from 'src/styles/themes/light';
import { addThousandSeparator } from 'src/utils/numbers/addThousandSeparator';
import { sleep } from 'src/utils/sleep';
import { transformUppercaseFirstLetter } from 'src/utils/strings/transformUppercaseFirstLetter';
import { updateCanSyncAdjust } from 'src/models/redux/reducers/Workspace';
import { Modal } from 'src/components/Modal';
import { ModalFooter } from 'src/components/Modal/Footer/styles';

import { NoPermissionToEditModal } from '../../../components/NoPermissionToEdit';
import { getSelectedFrequencyAndPeriod } from '../../utils/getSelectedFrequencyAndPeriod';
import { SessionExpiredModal } from '../Modals/SessionExpired';
import { UnsavedYsModal } from '../Modals/UnsavedYs';
import { GroupRowCell } from '../TableRenderer/GroupRowCell';
import { ValueCell } from '../TableRenderer/ValueCell';
import {
  ButtonUpdate,
  Container,
  DiscardingAdjustmentsContainer,
  DownloadButton,
  // DownloadButtonContainer,
  HeaderRightContent,
  SelectedFiltersExportContainer,
  SubmitApprovalContainer,
  TableButtonsContainer,
  TableContainer,
  TableError,
  TableStatus,
  Tag,
} from './styles';
import {
  Dates,
  DatesIds,
  EditedValue,
  EditedValuesByYs,
  Frequency,
  FrequencyIntervalInMonths,
  MarketShare,
  MarketShareAggregationValues,
  OriginalTableValues,
  RowData,
  SerieData,
  SerieDataIds,
  StartEndYForecastDates,
  TableColumns,
  UpdateStepStatusBody,
  YsDatesIds,
  YsInfo,
} from './types';
import { RequestApproval } from '../RequestApproval';
import { FlowMessage } from '../FlowMessage';
import { useQueryStepLog } from '../../../hooks/useQueryStepLog';
import { LogRecord } from '../../../hooks/useQueryStepLog/types';
import { ApproveOrDisapproveModal } from '../Modals/ApproveOrDisapprove';
import { ActionConfirmationModal } from '../Modals/ActionConfirmation';
import { useQueryReleaseData } from '../../../hooks/useQueryReleaseData';

const frequencyIntervalInMonths: FrequencyIntervalInMonths = {
  daily: {
    historical: 3,
    forecast: 1,
  },
  weekly: {
    historical: 13,
    forecast: 1,
  },
  fortnightly: {
    historical: 12,
    forecast: 2,
  },
  monthly: {
    historical: 12,
    forecast: 12,
  },
  bimonthly: {
    historical: 24,
    forecast: 12,
  },
  quarterly: {
    historical: 12,
    forecast: 6,
  },
  'half-year': {
    historical: 24,
    forecast: 24,
  },
  yearly: {
    historical: 48,
    forecast: 24,
  },
  annual: {
    historical: 48,
    forecast: 24,
  },
};

const variablesTypeRowId = [
  'row-group-type-marketshare',
  'row-group-type-marketsize',
  'row-group-type-sellout',
  'row-group-type-others',
];

export const Results: React.FC = () => {
  const [tableData, setTableData] = useState<RowData[]>([]);
  const [tableColumns, setTableColumns] = useState<TableColumns>([]);
  const [marketShare, setMarketShare] = useState<MarketShare[]>([]);
  const [marketShareAggregationValues, setMarketShareAggregationValues] =
    useState<MarketShareAggregationValues[]>([]);
  const [marketShareIsLoading, setMarketShareIsLoading] = useState(false);

  const [loadingEnableEdition, setLoadingEnableEdition] = useState(false);
  const [showUserIsEditingModal, setShowUserIsEditingModal] = useState('');
  const [showWorkspaceIsPublishing, setShowWorkspaceIsPublishing] =
    useState(false);
  const [editionExpired, setEditionExpired] = useState(false);

  const [editedValues, setEditedValues] = useState<EditedValue[]>([]);

  const [startEndYForecastDates, setStartEndYForecastDates] =
    useState<StartEndYForecastDates>({});

  const [invalidValues, setInvalidValues] = useState(0);

  const [seriesDataIsLoading, setSeriesDataIsLoading] = useState(false);
  const [seriesDataLoaded, setSeriesDataLoaded] = useState<string[]>([]);

  const [tableRendered, setTableRendered] = useState(false);

  const [ysDatesIds, setYsDatesIds] = useState<YsDatesIds>({});

  const [isSaving, setIsSaving] = useState(false);
  const [unsavedYs, setUnsavedYs] = useState<string[]>([]);

  const [isDiscardingAdjustments, setIsDiscardingAdjustments] = useState(false);
  const [showRequestApproval, setShowRequestApproval] = useState(false);
  const [showApproveModal, setShowApproveModal] = useState(false);
  const [showDisapproveModal, setShowDisapproveModal] = useState(false);
  const [
    showDiscardAdjustmentsConfirmation,
    setShowDiscardAdjustmentsConfirmation,
  ] = useState(false);

  const [buttonUpdateStatus, setButtonUpdateStatus] = useState<
    'opened' | 'closed' | null
  >(null);
  const [showUpdateStatusError, setShowUpdateStatusError] = useState(false);

  const tableRef = useRef<AgGridReact>(null);

  const [originalTableValues, setOriginalTableValues] = useState<
    OriginalTableValues[]
  >([]);

  const {
    auth: { user },
    workspace: {
      id: workspaceId,
      name,
      releaseSelected,
      frequency: workspaceFrequency,
      userRole,
      releasePreview,
    },
    workspaceOverviewOptions: {
      frequency,
      transformation,
      forecast: forecastType,
      period,
      editionModeEnabled,
      reviewModeEnabled,
      inflation,
      step,
      lastLogTimeByStep,
    },
  } = useSelector((state: RootState) => state);

  const { t: translate } = useTranslation();
  const dispatch = useDispatch();

  const { data: updatedStepLogData, isLoading: updatedStepLogIsLoading } =
    useQueryStepLog(
      workspaceId,
      releaseSelected?.id ?? null,
      step?.number ?? null,
      'updated_step',
      0,
      1,
    );

  const { data: adjustedStepLogData } = useQueryStepLog(
    workspaceId,
    releaseSelected?.id ?? null,
    step?.number ?? null,
    'adjusted',
    0,
    1,
  );

  const { data: releaseData, isLoading: releaseIsLoading } =
    useQueryReleaseData(workspaceId, releaseSelected?.id);

  const {
    data: ysInfoData,
    isLoading: ysInfoIsLoading,
    isFetching: ysInfoIsFetching,
    isError: ysInfoIsError,
  } = useQuery(
    ['workspace', 'ys info', workspaceId, releaseSelected?.id],
    async () => {
      const { data } = await apiWorkspace.get<YsInfo>(
        `workspaces/${workspaceId}/releases/${releaseSelected?.id}/ys`,
      );

      return data;
    },
    {
      enabled: !!workspaceId && !!releaseSelected?.id,
      staleTime: ms('1 day'),
    },
  );

  useEffect(() => {
    if (!ysInfoData) {
      setTableRendered(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ysInfoData]);

  useEffect(() => {
    (async () => {
      if (tableRendered && ysInfoData) {
        setTableRendered(false);

        await sleep(50);

        setTableRendered(true);
      }
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [releaseSelected?.id]);

  // console.log({
  //   lastLogTime,
  //   aux: adjustedStepLogData?.records[0]?.timestamp
  //     ? new Date(adjustedStepLogData?.records[0]?.timestamp).getTime()
  //     : null,
  //   records: adjustedStepLogData?.records,
  // });

  useEffect(() => {
    if (adjustedStepLogData?.records && step?.number !== undefined) {
      if (adjustedStepLogData.records.length > 0) {
        const adjustedLastLogTime = new Date(
          adjustedStepLogData.records[0].timestamp,
        ).getTime();

        if (typeof lastLogTimeByStep?.[step.number] !== 'number') {
          dispatch(
            changeLastLogTime({
              step: step.number,
              value: adjustedLastLogTime,
            }),
          );
        } else if (
          //@ts-expect-error: typescript bugou
          lastLogTimeByStep[step.number] < adjustedLastLogTime &&
          user.email !== adjustedStepLogData.records[0].user
        ) {
          setButtonUpdateStatus('opened');
        } else {
          setButtonUpdateStatus(null);
        }
      } else {
        dispatch(
          changeLastLogTime({
            step: step.number,
            value: 0,
          }),
        );
        setButtonUpdateStatus(null);
      }
    }
  }, [adjustedStepLogData, dispatch, lastLogTimeByStep, step, user.email]);

  const invalidateChartDataAndAggregationMarketShareData = async (
    lastStep?: number,
  ) => {
    queryClient.refetchQueries(['workspace', 'series data chart']);

    if (ysInfoData?.market_share?.enable) {
      await updateMarketShare(lastStep);
    }

    if (ysInfoData?.aggregation?.enable) {
      const refetchQueries: string[] = [];

      ysInfoData?.ys.forEach((y) => {
        const hierarchy = y.hierarchy.map((aux) => aux.value);
        for (let i = 1; i <= hierarchy.length; i++) {
          const queryKeyAdjustedNominal = [
            'workspace',
            'series data',
            workspaceId,
            releaseSelected?.id,
            hierarchy.slice(0, i).toString().replaceAll(',', ' '),
            'adjusted',
            lastStep ?? step?.number ?? 1,
            frequency,
            'variation',
            'nominal',
            y.type,
          ];

          if (
            !refetchQueries.includes(JSON.stringify(queryKeyAdjustedNominal))
          ) {
            refetchQueries.push(JSON.stringify(queryKeyAdjustedNominal));
            queryClient.refetchQueries(queryKeyAdjustedNominal);
          }

          const queryKeyAdjustedReal = [
            'workspace',
            'series data',
            workspaceId,
            releaseSelected?.id,
            hierarchy.slice(0, i).toString().replaceAll(',', ' '),
            'adjusted',
            lastStep ?? step?.number ?? 1,
            frequency,
            'variation',
            'real',
            y.type,
          ];

          if (!refetchQueries.includes(JSON.stringify(queryKeyAdjustedReal))) {
            refetchQueries.push(JSON.stringify(queryKeyAdjustedReal));
            queryClient.refetchQueries(queryKeyAdjustedReal);
          }
        }
      });
    }
  };

  const handleSaveChanges = async () => {
    try {
      await apiWorkspace.patch(`/workspaces/${workspaceId}/edit`);

      const editedValuesByYs: EditedValuesByYs = {};
      const labels: string[] = [];

      editedValues.forEach((edited) => {
        const label = edited.y_label;

        const editedValuesY = editedValuesByYs[label];

        if (!editedValuesY) {
          labels.push(label);
          editedValuesByYs[label] = [];
        }

        editedValuesByYs[label].push({
          date: `${edited.date}:00Z`,
          value: edited.value,
          id: ysDatesIds[label][edited.date],
        });
      });

      let errorToSave = false;
      const inflationAux = inflation === 'nominal' ? 'inflate' : 'original';

      for (let i = 0; i < labels.length; i++) {
        try {
          await apiWorkspace.put(
            `/workspaces/${workspaceId}/releases/${
              releaseSelected?.id
            }/series/raw/${labels[i]}?inflation=${inflationAux}&step=${
              step?.number ?? 1
            }`,
            { values: editedValuesByYs[labels[i]] },
          );

          queryClient.invalidateQueries([
            'workspace',
            'series data',
            workspaceId,
            releaseSelected?.id,
            labels[i],
            forecastType,
            step?.number ?? 1,
          ]);
        } catch {
          errorToSave = true;

          setUnsavedYs((prev) => [...prev, labels[i]]);
        }
      }

      if (step) {
        await queryClient.refetchQueries({
          queryKey: [
            'workspace',
            workspaceId,
            'releases',
            releaseSelected?.id,
            'logs',
            step.number,
          ],
        });

        queryClient.refetchQueries(['workspace', 'logs']);
      }

      invalidateChartDataAndAggregationMarketShareData();

      if (!errorToSave) {
        dispatch(changeEditionModeEnabled(false));
        dispatch(updateCanSyncAdjust(true));
        setEditedValues([]);

        await unlockEdition();

        setOriginalTableValues([]);
      }
    } catch (err) {
      const error = err as AxiosError;
      if (error.response?.status === 400) {
        if (
          error.response?.data?.detail?.detail?.startsWith(
            'Workspace already locked for editing by ',
          )
        ) {
          setShowUserIsEditingModal(
            error.response.data.detail.detail.replace(
              'Workspace already locked for editing by ',
              '',
            ),
          );
        } else if (
          error.response?.data?.detail?.detail ===
          'You cannot perform this action, Workspace is publishing a new version.'
        ) {
          setShowWorkspaceIsPublishing(true);
        }
      }
    }

    setIsSaving(false);
  };

  const handleDownloadTable = (downloadAllData: boolean) => {
    if (tableRef?.current) {
      let quantityColumnFilter = ysInfoData?.hierarchies.length ?? 0;

      if (ysInfoData?.market_share?.enable) {
        quantityColumnFilter += 1;
      }

      if (quantityColumnFilter >= 1) {
        tableRef.current.api.moveColumnByIndex(0, quantityColumnFilter);
      }

      const config: ExcelExportParams = {
        sheetName: translate('workspaceOverviewResultsTitle'),
        fileName: `${name} - ${releaseSelected?.name}`,
        exportedRows: downloadAllData ? 'all' : 'filteredAndSorted',
        suppressRowOutline: true,
        allColumns: quantityColumnFilter >= 1,
        shouldRowBeSkipped(params) {
          if (variablesTypeRowId.includes(params.node.id ?? '')) return true;

          return !(
            Object.keys(params.node?.aggData ?? {}).length >= 1 ||
            Object.keys(params.node?.data ?? {}).length >= 1
          );
        },
        processCellCallback(params) {
          if (
            params.value === undefined &&
            params.node?.parent?.key &&
            params.column.getColId() === params.node.parent?.field
          ) {
            return params.node?.parent.key === 'marketshare'
              ? 'Market Share (%)'
              : params.node?.parent.key;
          }

          if (
            params.value === undefined &&
            params.node?.key &&
            params.column.getColId() === params.node.field
          ) {
            return params.node.key === 'marketshare'
              ? 'Market Share (%)'
              : params.node.key;
          }

          if (params.column.getColId() === 'ag-Grid-AutoColumn') {
            if (params.node?.data?.y_label === params.value) {
              if (
                ysInfoData?.market_share?.enable &&
                params?.node?.data?.type === 'marketshare'
              ) {
                return 'total';
              }

              return params.value === 'marketshare'
                ? 'Market Share (%)'
                : params.value;
            }

            return 'total';
          }

          if (
            !params.value &&
            Number.isNaN(new Date(params.column.getColId()).getDate())
          ) {
            if (
              ysInfoData?.market_share?.enable &&
              params.column.getColId() === 'type'
            ) {
              if (params.node?.id?.includes('sellout')) {
                return 'Sell Out';
              }
              if (params.node?.id?.includes('marketsize')) {
                return 'Market Size';
              }
              if (params.node?.id?.includes('marketshare')) {
                return 'Market Share (%)';
              }
              if (params.node?.id?.includes('others')) {
                return translate('workspaceOverviewOthers');
              }
            }
            if (
              ysInfoData?.market_share?.enable &&
              params.node?.data?.type === 'marketshare'
            ) {
              if (params.column.getColId() === ysInfoData.hierarchies.at(-1)) {
                return params.node?.data?.y_label || 'total';
              }
            }

            if (params.node?.id?.includes(params.column.getColId())) {
              if (params.node.allLeafChildren.length) {
                const childIndex = params.node.allLeafChildren.findIndex(
                  (child) => child?.data?.[params.column.getColId()],
                );

                if (childIndex !== -1) {
                  return params.node.allLeafChildren[childIndex]?.data?.[
                    params.column.getColId()
                  ];
                }
              }
            }

            return 'total';
          }

          if (
            !params?.value &&
            ysInfoData?.hierarchies.includes(params.column.getColId())
          ) {
            return 'total';
          }

          if (
            typeof params.value === 'number' &&
            transformation === 'variation'
          ) {
            //@ts-expect-error: ignora
            return getOriginalValue(params.node, params.column.getColId());
          }

          if (
            ysInfoData?.market_share?.enable &&
            params.column.getColId() === 'type'
          ) {
            return params.value === 'marketshare'
              ? 'Market Share (%)'
              : params.value === 'sellout'
              ? 'Sell Out'
              : params.value === 'marketsize'
              ? 'Market Size'
              : params.value === 'others'
              ? translate('workspaceOverviewOthers')
              : params.value;
          }

          return params.value;
        },
        processGroupHeaderCallback(params) {
          const columnName = params.api.getDisplayNameForColumnGroup(
            params.columnGroup,
            null,
          );

          if (['Historical', 'Histórico'].includes(columnName))
            return translate('Historical');

          if (['Forecast', 'Projeção'].includes(columnName))
            return translate('Forecast');

          return columnName;
        },
        processHeaderCallback(params) {
          if (params.column.getColDef().field === 'y_label') {
            return translate('variable');
          }

          return (
            //@ts-expect-error: ignora
            params.column?.userProvidedColDef?.headerName ??
            params.column.getColDef().field!
          );
        },
        processRowGroupCallback(params) {
          return params.node.key!;
        },
      };

      tableRef.current!.api.exportDataAsExcel(config);
    }
  };

  const handleEditedValue = async (
    y_label: string,
    date: string,
    value: number,
    oldValue: number,
    rowId: string,
  ) => {
    if (y_label) {
      const variableIndex = editedValues.findIndex(
        (y) => y.y_label === y_label && y.date === date,
      );

      const updatedEditedValues = [...editedValues];

      const serieData = await getSerie(y_label, 'original');
      const serieDataAdjusted = await getSerie(y_label, 'adjusted');

      const index = serieData.forecast?.dates.findIndex(
        (dateAux) => dateAux.replace(':00Z', '') === date,
      );
      const indexSerieDataAdjusted =
        serieDataAdjusted.forecast?.dates.findIndex(
          (dateAux) => dateAux.replace(':00Z', '') === date,
        );

      const originalValue =
        serieData.forecast && typeof index === 'number' && index !== -1
          ? serieData.forecast.values[index]
          : oldValue;

      const adjustedValue =
        serieDataAdjusted.forecast &&
        typeof indexSerieDataAdjusted === 'number' &&
        indexSerieDataAdjusted !== -1
          ? serieDataAdjusted.forecast.values[indexSerieDataAdjusted]
          : oldValue;

      if (variableIndex === -1) {
        updatedEditedValues.push({
          y_label,
          date,
          value,
          oldValue,
          originalValue,
          adjustedValue,
          rowId,
        });
      } else {
        updatedEditedValues[variableIndex].value = value;
      }

      setEditedValues(updatedEditedValues);

      if (value <= 0 && oldValue > 0 && y_label) {
        setInvalidValues(invalidValues + 1);
      }

      if (oldValue <= 0 && value > 0 && y_label) {
        setInvalidValues(invalidValues - 1);
      }
    }
  };

  const handleDiscardChanges = async () => {
    editedValues.forEach((y) => {
      const rowNode = tableRef.current!.api.getRowNode(y.rowId)!;

      rowNode.setDataValue(y.date, y.adjustedValue);
    });

    setEditedValues([]);
    dispatch(changeEditionModeEnabled(false));

    await unlockEdition();
  };

  const updateStepStatus = async (
    status: StepStatus,
    message?: string,
  ): Promise<boolean> => {
    if (!step || step.status === status) return true;

    try {
      const updatedStep: Step = { ...step, status, selectedStatus: status };

      const body: UpdateStepStatusBody = { status };

      if (status === 'awaiting_approval' && message) {
        updatedStep.awaiting_approval_messages = [
          message,
          ...updatedStep.awaiting_approval_messages,
        ];

        body.awaiting_approval_messages = [message];
      }

      if (status === 'approved' && message) {
        updatedStep.approval_messages = [
          message,
          ...updatedStep.approval_messages,
        ];

        body.approval_messages = [message];
      }

      if (status === 'adjusting' && message) {
        updatedStep.disapproval_messages = [
          message,
          ...updatedStep.disapproval_messages,
        ];

        body.disapproval_messages = [message];
      }

      await apiWorkspace.put<Step>(
        `/workspaces/${workspaceId}/releases/${releaseSelected?.id}/steps/${step?.number}`,
        body,
      );

      dispatch(changeWorkspaceOverviewStep(updatedStep));

      if (status === 'awaiting_approval') {
        await unlockEdition();
      }

      queryClient.refetchQueries({
        queryKey: [
          'workspace',
          workspaceId,
          'releases',
          releaseSelected?.id,
          'logs',
          step.number,
        ],
      });

      queryClient.refetchQueries(['workspace', 'logs']);

      queryClient.refetchQueries({
        queryKey: ['workspace', workspaceId, 'releases', releaseSelected?.id],
        exact: true,
      });

      return true;
    } catch {
      setShowUpdateStatusError(true);

      return false;
    }
  };

  const lockEdition = async () => {
    try {
      await apiWorkspace.patch(`/workspaces/${workspaceId}/edit`);

      return true;
    } catch (err) {
      const error = err as AxiosError;
      if (error.response?.status === 400) {
        if (
          error.response?.data?.detail?.detail?.startsWith(
            'Workspace already locked for editing by ',
          )
        ) {
          setShowUserIsEditingModal(
            error.response.data.detail.detail.replace(
              'Workspace already locked for editing by ',
              '',
            ),
          );
        } else if (
          error.response?.data?.detail?.detail ===
          'You cannot perform this action, Workspace is publishing a new version.'
        ) {
          setShowWorkspaceIsPublishing(true);
        }
      }

      return false;
    }
  };

  const unlockEdition = async () => {
    try {
      await apiWorkspace.delete(`/workspaces/${workspaceId}/edit`);
      // eslint-disable-next-line no-empty
    } catch {}
  };

  const handleEnableEdition = async () => {
    setLoadingEnableEdition(true);

    const success = await lockEdition();

    if (success) {
      if (step?.number === 1) {
        updateStepStatus('adjusting');
      }

      if (!editionModeEnabled) {
        dispatch(changeEditionModeEnabled(true));
      }

      setEditionExpired(false);
    }

    setLoadingEnableEdition(false);
  };

  const handleApproveReview = async (message: string) => {
    await updateStepStatus('approved', message);

    setShowApproveModal(false);
    dispatch(changeReviewModeEnabled(false));
  };

  const handleDisapproveReview = async (message: string) => {
    await updateStepStatus('adjusting', message);

    setShowDisapproveModal(false);
    dispatch(changeReviewModeEnabled(false));
  };

  const handleStartReview = () => {
    dispatch(changeReviewModeEnabled(true));
  };

  const handleDiscardAllAdjustments = async () => {
    if (!step) return;

    const success = await lockEdition();

    if (success) {
      try {
        await apiWorkspace.patch<Step>(
          `/workspaces/${workspaceId}/releases/${releaseSelected?.id}/steps/${step?.number}`,
        );

        dispatch(
          changeWorkspaceOverviewStep({
            ...step,
            approval_messages: [],
            awaiting_approval_messages: [],
            disapproval_messages: [],
          }),
        );

        queryClient.refetchQueries(['workspace', 'logs']);

        queryClient.refetchQueries({
          queryKey: ['workspace', workspaceId, 'releases', releaseSelected?.id],
          exact: true,
        });

        setIsDiscardingAdjustments(true);
        setTableRendered(false);

        // eslint-disable-next-line no-empty
      } catch {}

      await unlockEdition();
    }
  };

  const dateFormatter = useCallback(
    (date: string): string => {
      let dateFormat = 'MMM/yy';

      if (frequency === 'original' && !!workspaceFrequency) {
        if (['annual', 'yearly'].includes(workspaceFrequency)) {
          dateFormat = 'yyyy';
        } else if (
          workspaceFrequency === 'daily' ||
          workspaceFrequency === 'fortnightly' ||
          workspaceFrequency === 'weekly'
        ) {
          dateFormat = user.language === 'en-us' ? 'MM/dd/yy' : 'dd/MM/yy';
        }
      }

      if (['annual', 'yearly'].includes(frequency)) {
        dateFormat = 'yyyy';
      }

      return format(new Date(date.replace(':00Z', '')), dateFormat, {
        locale: user.language === 'en-us' ? enUS : ptBR,
      });
    },
    [frequency, workspaceFrequency, user.language],
  );

  const getAllParentKey = useCallback((rowNode: IRowNode | null): string => {
    if (rowNode && rowNode?.key) {
      return `${getAllParentKey(rowNode.parent)} ${rowNode.key}`.trim();
    }
    return '';
  }, []);

  const getAdjustedVariationValue = useCallback(
    (rowNode: IRowNode, column: string) => {
      let y_label = '';
      let isAggregation = false;
      let aggregationKey = '';

      const isMarketShare =
        rowNode.id?.includes('marketshare') ||
        rowNode?.parent?.id?.includes('marketshare');

      if (rowNode.data?.y_label) {
        y_label = rowNode.data?.y_label;
        if (isMarketShare) {
          aggregationKey = getAllParentKey(rowNode.parent);
          aggregationKey = aggregationKey.concat(` ${y_label}`);
        }
      } else {
        y_label = rowNode.key || '';
        isAggregation = true;
        aggregationKey = getAllParentKey(rowNode);
      }

      const type = aggregationKey.includes('marketsize')
        ? 'marketsize'
        : aggregationKey.includes('sellout')
        ? 'sellout'
        : aggregationKey.includes('others')
        ? 'others'
        : null;

      if (ysInfoData?.market_share?.enable) {
        aggregationKey = aggregationKey
          .replace('marketsize', '')
          .replace('sellout', '')
          .replace('marketshare', '')
          .replace('others', '')
          .trim();
      }

      let originalValue: null | number = null;

      if (isMarketShare) {
        aggregationKey = aggregationKey || 'marketshare';
      }

      if (y_label) {
        const queryKey = [
          'workspace',
          'series data',
          workspaceId,
          releaseSelected?.id,
          isAggregation || isMarketShare ? aggregationKey : y_label,
          'adjusted',
          step?.number ?? 1,
          frequency,
          transformation,
          inflation,
        ];

        if (isMarketShare) {
          queryKey.push('marketshare');
        } else if (type && ysInfoData?.market_share?.enable) {
          queryKey.push(type);
        }

        const response: SerieData | undefined =
          queryClient.getQueryData(queryKey);
        const queryState = queryClient.getQueryState(queryKey);

        if (
          response &&
          response.historical.dates &&
          response.forecast?.dates &&
          !queryState?.isInvalidated &&
          !queryState?.isFetching
        ) {
          const historicalIndex = response.historical?.dates.findIndex(
            (dateAux) => dateAux.replace(':00Z', '') === column,
          );

          if (typeof historicalIndex === 'number' && historicalIndex !== -1) {
            originalValue = response.historical.values[historicalIndex];
          }

          if (!originalValue) {
            const forecastIndex = response.forecast?.dates.findIndex(
              (dateAux) => dateAux.replace(':00Z', '') === column,
            );

            if (typeof forecastIndex === 'number' && forecastIndex !== -1) {
              originalValue = response.forecast.values[forecastIndex];
            }
          }
        }
      }

      const defaultNodeValue = originalTableValues.find(
        (node) => node.id === rowNode.id,
      );

      if (typeof originalValue === 'number') return originalValue;

      if (!defaultNodeValue) return 0;

      return (
        defaultNodeValue.aggData?.[column] ??
        defaultNodeValue.data?.[column] ??
        0
      );
    },
    [
      originalTableValues,
      frequency,
      getAllParentKey,
      inflation,
      releaseSelected?.id,
      transformation,
      workspaceId,
      ysInfoData?.market_share?.enable,
      step?.number,
    ],
  );

  const getOriginalValue = useCallback(
    (rowNode: IRowNode, column: string) => {
      let y_label = '';
      let isAggregation = false;
      let aggregationKey = '';

      const isMarketShare =
        rowNode.id?.includes('marketshare') ||
        rowNode?.parent?.id?.includes('marketshare');

      if (rowNode.data?.y_label) {
        y_label = rowNode.data?.y_label;
        if (isMarketShare) {
          aggregationKey = getAllParentKey(rowNode.parent);
          aggregationKey = aggregationKey.concat(` ${y_label}`);
        }
      } else {
        y_label = rowNode.key || '';
        isAggregation = true;
        aggregationKey = getAllParentKey(rowNode);
      }

      const type = aggregationKey.includes('marketsize')
        ? 'marketsize'
        : aggregationKey.includes('sellout')
        ? 'sellout'
        : aggregationKey.includes('others')
        ? 'others'
        : null;

      if (ysInfoData?.market_share?.enable) {
        aggregationKey = aggregationKey
          .replace('marketsize', '')
          .replace('sellout', '')
          .replace('marketshare', '')
          .replace('others', '')
          .trim();
      }

      let originalValue: null | number = null;

      if (isMarketShare) {
        aggregationKey = aggregationKey || 'marketshare';
      }

      if (y_label) {
        const queryKey = [
          'workspace',
          'series data',
          workspaceId,
          releaseSelected?.id,
          isAggregation || isMarketShare ? aggregationKey : y_label,
          'original',
          step?.number ?? 1,
          frequency,
          transformation,
          inflation,
        ];

        if (isMarketShare) {
          queryKey.push('marketshare');
        } else if (type && ysInfoData?.market_share?.enable) {
          queryKey.push(type);
        }

        const response: SerieData | undefined =
          queryClient.getQueryData(queryKey);
        const queryState = queryClient.getQueryState(queryKey);

        if (
          response &&
          response.historical.dates &&
          response.forecast?.dates &&
          !queryState?.isInvalidated &&
          !queryState?.isFetching
        ) {
          if (isAggregation && transformation === 'variation') {
            const historicalIndex = response.historical?.dates.findIndex(
              (dateAux) => dateAux.replace(':00Z', '') === column,
            );

            if (typeof historicalIndex === 'number' && historicalIndex !== -1) {
              originalValue = response.historical.values[historicalIndex];
            }
          }

          if (!originalValue) {
            const forecastIndex = response.forecast?.dates.findIndex(
              (dateAux) => dateAux.replace(':00Z', '') === column,
            );

            if (typeof forecastIndex === 'number' && forecastIndex !== -1) {
              originalValue = response.forecast.values[forecastIndex];
            }
          }
        }
      }

      const defaultNodeValue = originalTableValues.find(
        (node) => node.id === rowNode.id,
      );

      if (typeof originalValue === 'number') return originalValue;

      if (!defaultNodeValue) return 0;

      return (
        defaultNodeValue.aggData?.[column] ??
        defaultNodeValue.data?.[column] ??
        0
      );
    },
    [
      originalTableValues,
      frequency,
      getAllParentKey,
      inflation,
      releaseSelected?.id,
      transformation,
      workspaceId,
      ysInfoData?.market_share?.enable,
      step?.number,
    ],
  );

  const defaultColDef: ColDef = useMemo(
    () => ({
      flex: 1,
      minWidth: 90,
      sortable: false,
      unSortIcon: true,
      resizable: false,
      suppressMovable: true,
    }),
    [],
  );

  const autoGroupColumnDef: ColDef = useMemo(
    () => ({
      headerName: translate('workspaceOverviewResultsGroup'),
      field: 'y_label',
      pinned: 'left',
      flex: 1,
      minWidth: 115,
      width: 250,
      autoHeight: true,
      suppressMovable: true,
      lockPinned: true,
      cellRendererParams: {
        suppressCount: true,
      },
      enableRowGroup: true,
      resizable: true,
      filter: 'agSetColumnFilter',
      menuTabs: ['filterMenuTab'],
      suppressHeaderMenuButton: true,
      filterParams:
        ysInfoData?.hierarchies.length || ysInfoData?.market_share?.enable
          ? {
              treeList: true,
              keyCreator: (params: any) =>
                params.value ? params.value.join('#') : null,
            }
          : undefined,
      cellRenderer: (row: ICellRendererParams) => (
        <GroupRowCell
          row={row}
          isLoading={seriesDataIsLoading}
          isMarketShareLoading={marketShareIsLoading}
        />
      ),
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [seriesDataIsLoading, marketShareIsLoading],
  );

  const aggFunc = useCallback(
    (params: IAggFuncParams) => {
      if (
        marketShareAggregationValues.length &&
        params.rowNode.id?.includes('marketshare')
      ) {
        const marketShareAux = marketShareAggregationValues.find((market) => {
          if (
            market.isAggregation &&
            Object.keys(market).length === 2 &&
            params.rowNode.key === 'marketshare'
          ) {
            return true;
          }

          return (
            market.isAggregation &&
            market?.[params.rowNode.field ?? ''] === params.rowNode.key
          );
        });

        if (marketShareAux) {
          return (
            marketShareAux._values?.[transformation]?.[
              params.colDef.field ?? ''
            ] ?? undefined
          );
        }

        return undefined;
      }
      if (ysInfoData?.aggregation?.enable) {
        let sum = 0;

        if (params.values.every((value) => Number.isNaN(Number(value)))) {
          return undefined;
        }

        params.values.forEach((value) => {
          sum += value || 0;
        });

        return sum;
      }

      return undefined;
    },
    [
      marketShareAggregationValues,
      transformation,
      ysInfoData?.aggregation?.enable,
    ],
  );

  const excelStyle: ExcelStyle[] = useMemo(() => {
    if (ysInfoData) {
      const excelBorders = {
        borderBottom: {
          color: light.colors.gray3,
        },
        borderTop: {
          color: light.colors.gray3,
        },
        borderLeft: {
          color: light.colors.gray3,
        },
        borderRight: {
          color: light.colors.gray3,
        },
      };

      const defaultOptions: ExcelStyle[] = [
        {
          id: 'header',
          alignment: {
            horizontal: 'Center',
          },
          borders: excelBorders,
        },
        {
          id: 'forecast-values',
          font: {
            color: light.colors.secondary,
          },
          interior: {
            color: '#FEF5F9',
            pattern: 'Solid',
          },
          borders: excelBorders,
        },
        {
          id: 'historical-values',
          borders: excelBorders,
        },
      ];

      return defaultOptions;
    }

    return [];
  }, [ysInfoData]);

  const isForecast = useCallback(
    (y: string, date: Date) => {
      const { startDate, endDate } = startEndYForecastDates[y] ?? {};

      return (
        !!startDate &&
        !!endDate &&
        date >= new Date(startDate) &&
        date <= new Date(endDate)
      );
    },
    [startEndYForecastDates],
  );

  const editable = useCallback(
    (y: string, date: Date) =>
      editionModeEnabled &&
      isForecast(y, date) &&
      !!ysInfoData?.ys.some((yAux) => yAux.y_label === y),
    [isForecast, editionModeEnabled, ysInfoData?.ys],
  );

  const cellClassName = useCallback(
    (y: string, date: Date) => {
      const { startDate } = startEndYForecastDates[y] ?? {};

      return !!startDate && date >= new Date(startDate)
        ? 'forecast-values'
        : 'historical-values';
    },
    [startEndYForecastDates],
  );

  const getSerie = useCallback(
    async (
      serie: string,
      forecastTypeAux: WorkspaceProjectionsForecastType,
      aggregationQuery: string[] = [],
      type: string | null = null,
      isMarketShare = false,
      lastStep?: number,
    ) => {
      const queryKey = [
        'workspace',
        'series data',
        workspaceId,
        releaseSelected?.id,
        aggregationQuery.toString().replaceAll(',', ' ') || serie,
        forecastTypeAux,
        lastStep ?? step?.number ?? 1,
        frequency,
        transformation,
        inflation,
      ];

      if (isMarketShare) {
        queryKey.push('marketshare');
      } else if (type && ysInfoData?.market_share?.enable) {
        queryKey.push(type);
      }

      let queryData: SerieData;

      const response = queryClient.getQueryData<SerieData>(queryKey);
      const queryState = queryClient.getQueryState(queryKey);

      const isYInflate = releaseSelected?.data.ys.find(
        (y) => y.y_label === serie,
      )?.is_inflated;

      let inflationAux: 'inflate' | 'original' = 'inflate';

      if (
        inflation === 'real' ||
        (!aggregationQuery.length &&
          typeof isYInflate === 'boolean' &&
          isYInflate === false)
      ) {
        inflationAux = 'original';
      }

      if (response && !queryState?.isInvalidated && !queryState?.isFetching) {
        queryData = response;
      } else {
        let route = '';

        if (aggregationQuery.length || type) {
          let hierarchy = '';
          aggregationQuery.forEach((aggregation) => {
            hierarchy = hierarchy.concat(
              `hierarchy=${encodeURI(aggregation)}&`,
            );
          });

          if (isMarketShare) {
            route = `/workspaces/${workspaceId}/releases/${
              releaseSelected?.id
            }/series/calculated?calc=marketshare&${hierarchy}frequency=${frequency}&transformation=${transformation}&inflation=${inflationAux}&data_type=${forecastTypeAux}&step=${
              step?.number ?? 1
            }`;
          } else {
            const typeAux = type ? encodeURI(`filter=y_type=${type}&`) : '';
            route = `/workspaces/${workspaceId}/releases/${
              releaseSelected?.id
            }/series/calculated?calc=sum&${hierarchy}${typeAux}frequency=${frequency}&transformation=${transformation}&inflation=${inflationAux}&data_type=${forecastTypeAux}&step=${
              step?.number ?? 1
            }`;
          }
        } else {
          route = `/workspaces/${workspaceId}/releases/${
            releaseSelected?.id
          }/series/raw/${serie}?frequency=${frequency}&transformation=${transformation}&inflation=${inflationAux}&data_type=${forecastTypeAux}&step=${
            step?.number ?? 1
          }`;
        }

        queryData = await queryClient.fetchQuery<SerieData>(
          queryKey,
          async () => {
            const { data } = await apiWorkspace.get<SerieData>(route);

            return {
              serie,
              historical: data.historical,
              forecast: data?.forecast,
            };
          },
          {
            staleTime: ms('1 day'),
          },
        );
      }

      return queryData;
    },
    [
      frequency,
      inflation,
      releaseSelected?.data.ys,
      releaseSelected?.id,
      transformation,
      workspaceId,
      ysInfoData?.market_share?.enable,
      step?.number,
    ],
  );

  const updateMarketShare = useCallback(
    async (lastStep?: number) => {
      if (marketShare.length) {
        setMarketShareIsLoading(true);
        const marketShareAggregationValuesAux: MarketShareAggregationValues[] =
          [];

        let promiseMarketShare: any[] = [];

        for (let i = 0; i < marketShare.length; i++) {
          const objAux: MarketShare = { ...marketShare[i] };

          //@ts-expect-error:ignora
          delete objAux.isAggregation;
          delete objAux.rowId;

          const keys = Object.keys(objAux);
          const hierarchy = keys.map((key) => String(objAux[key]));

          const queryKeyAdjusted = [
            'workspace',
            'series data',
            workspaceId,
            releaseSelected?.id,
            hierarchy.toString().replaceAll(',', ' ') || 'marketshare',
            'adjusted',
            lastStep ?? step?.number ?? 1,
            frequency,
            transformation,
            inflation,
            'marketshare',
          ];

          const queryKeyOriginal = [
            'workspace',
            'series data',
            workspaceId,
            releaseSelected?.id,
            hierarchy.toString().replaceAll(',', ' ') || 'marketshare',
            'original',
            lastStep ?? step?.number ?? 1,
            frequency,
            transformation,
            inflation,
            'marketshare',
          ];

          await queryClient.invalidateQueries(queryKeyAdjusted);
          await queryClient.invalidateQueries(queryKeyOriginal);

          promiseMarketShare.push(
            getSerie(
              objAux[keys.at(-1)!]?.toString() || 'marketshare',
              'original',
              hierarchy,
              'marketshare',
              true,
              lastStep,
            ),
          );

          promiseMarketShare.push(
            getSerie(
              objAux[keys.at(-1)!]?.toString() || 'marketshare',
              'adjusted',
              hierarchy,
              'marketshare',
              true,
              lastStep,
            ),
          );

          if (promiseMarketShare.length >= 200) {
            await Promise.all(promiseMarketShare);
            promiseMarketShare = [];
          }
        }

        await Promise.all(promiseMarketShare);

        const startEndYForecastDatesAux: StartEndYForecastDates = {};
        for (let i = 0; i < marketShare.length; i++) {
          const objAux: MarketShare = { ...marketShare[i] };

          //@ts-expect-error:ignora
          delete objAux.isAggregation;
          delete objAux.rowId;

          const keys = Object.keys(objAux);
          const hierarchy = keys.map((key) => String(objAux[key]));

          const original = await getSerie(
            objAux[keys.at(-1)!]?.toString() || 'marketshare',
            'original',
            hierarchy,
            'marketshare',
            true,
            lastStep,
          );

          const adjusted = await getSerie(
            objAux[keys.at(-1)!]?.toString() || 'marketshare',
            'adjusted',
            hierarchy,
            'marketshare',
            true,
            lastStep,
          );

          const dataAdjusted: { [key: string]: number } = {};

          const dataAux = forecastType === 'adjusted' ? adjusted : original;

          dataAux.historical.dates.forEach((date, index) => {
            dataAdjusted[date.replace(':00Z', '')] =
              dataAux.historical.values[index];
          });

          dataAux.forecast?.dates.forEach((date, index) => {
            dataAdjusted[date.replace(':00Z', '')] =
              dataAux.forecast!.values[index];
          });

          if (marketShare[i].rowId) {
            const rowNode = tableRef.current!.api.getRowNode(
              marketShare[i].rowId!.toString(),
            );

            rowNode?.updateData({
              ...rowNode.data,
              ...dataAdjusted,
              id: marketShare[i].rowId,
            });

            startEndYForecastDatesAux[String(marketShare[i].y_label)] = {
              startDate: dataAux.forecast?.dates.length
                ? dataAux.forecast.dates[0]?.replace(':00Z', '')
                : null,
              endDate: dataAux.forecast?.dates.length
                ? dataAux.forecast.dates[
                    dataAux.forecast.dates.length - 1
                  ]?.replace(':00Z', '')
                : null,
            };
          } else {
            marketShareAggregationValuesAux.push({
              ...marketShare[i],
              _values: {
                [transformation]: dataAdjusted,
              },
            });
          }
        }

        setStartEndYForecastDates((state) => ({
          ...state,
          ...startEndYForecastDatesAux,
        }));
        setMarketShareAggregationValues(marketShareAggregationValuesAux);
        setMarketShareIsLoading(false);
      }
    },
    [
      forecastType,
      frequency,
      getSerie,
      inflation,
      marketShare,
      releaseSelected?.id,
      transformation,
      workspaceId,
      step?.number,
    ],
  );

  useEffect(() => {
    if (tableRendered) {
      updateMarketShare();
    }
  }, [marketShare, updateMarketShare, tableRendered]);

  const getSerieData = useCallback(
    async (
      index: number,
      allDates: Set<string>,
      hasSuccessData: boolean,
      startEndYForecastDatesAux: StartEndYForecastDates,
      data: SerieData,
      defaultPeriod: Dates,
    ): Promise<SerieDataIds | undefined> => {
      const getDefaultDateInterval = (): Dates => {
        const lastHistoricalDate = data.historical.dates.at(-1);
        const firstForecastDate = data.forecast?.dates[0];

        const selectedFrequency: Frequency =
          frequency === 'original'
            ? workspaceFrequency ?? 'monthly'
            : frequency;

        if (!lastHistoricalDate && !firstForecastDate)
          dispatch(changeWorkspaceOverviewPeriod([null, null]));

        const firstDate = subMonths(
          new Date(
            (lastHistoricalDate ?? firstForecastDate ?? '').replace(':00Z', ''),
          ),
          selectedFrequency === 'monthly'
            ? frequencyIntervalInMonths[selectedFrequency].historical - 1
            : frequencyIntervalInMonths[selectedFrequency].historical,
        );

        const lastDate = addMonths(
          new Date(
            (firstForecastDate ?? lastHistoricalDate ?? '').replace(':00Z', ''),
          ),
          selectedFrequency === 'monthly'
            ? frequencyIntervalInMonths[selectedFrequency].forecast - 1
            : frequencyIntervalInMonths[selectedFrequency].forecast,
        );

        return [firstDate, lastDate];
      };

      const addDateColumn = (
        type: 'historicalValue' | 'forecastValue',
        historicalColumns: ColDef[],
        forecastColumns: ColDef[],
        newDate: string,
        hideColumn: boolean,
      ) => {
        const headerName = dateFormatter(newDate);

        const columnsArray =
          type === 'historicalValue' ? historicalColumns : forecastColumns;
        const hasColumn = columnsArray.some(
          (column) => column.field === newDate,
        );

        if (!hasSuccessData && !allDates.has(newDate) && !hasColumn) {
          const column = {
            field: newDate,
            type: [type, 'defaultConfig'],
            headerName,
            hide: hideColumn,
          };

          if (type === 'historicalValue') {
            historicalColumns.push(column);
          } else {
            forecastColumns.push(column);
          }
        } else if (!allDates.has(newDate) && !hasColumn) {
          const historicalPosition = historicalColumns.findIndex(
            ({ field }) => new Date(field as string) > new Date(newDate),
          );

          if (historicalPosition !== -1) {
            historicalColumns.splice(historicalPosition, 0, {
              field: newDate,
              type: ['historicalValue', 'defaultConfig'],
              headerName,
              hide: hideColumn,
            });
          } else {
            const forecastPosition = forecastColumns.findIndex(
              ({ field }) => new Date(field as string) > new Date(newDate),
            );

            if (forecastPosition !== -1) {
              forecastColumns.splice(forecastPosition, 0, {
                field: newDate,
                type: ['forecastValue', 'defaultConfig'],
                headerName,
                hide: hideColumn,
              });
            } else {
              forecastColumns.push({
                field: newDate,
                type: ['forecastValue', 'defaultConfig'],
                headerName,
                hide: hideColumn,
              });
            }
          }
        }

        allDates.add(newDate);
      };

      const updateTableColumns = (dates: Dates) => {
        const tableApi = tableRef.current?.api;

        if (tableApi) {
          const updatedColumns = tableApi!.getGridOption('columnDefs') ?? [];

          const historical = (updatedColumns[1] as ColGroupDef)?.children ?? [];
          const forecast = (updatedColumns[2] as ColGroupDef)?.children ?? [];

          const rowData: RowData = {};

          data.historical.dates.forEach((date, i) => {
            const updatedDate = date.replace(':00Z', '');
            const formattedDate = new Date(updatedDate);

            addDateColumn(
              'historicalValue',
              historical,
              forecast,
              updatedDate,
              !!dates[0] &&
                !!dates[1] &&
                (formattedDate < dates[0] || formattedDate > dates[1]),
            );

            rowData[updatedDate] = data.historical.values[i];
          });

          const yDatesIds: DatesIds = {};

          data.forecast?.dates.forEach((date, i) => {
            const updatedDate = date.replace(':00Z', '');
            const formattedDate = new Date(updatedDate);

            addDateColumn(
              'forecastValue',
              historical,
              forecast,
              updatedDate,
              !!dates[0] &&
                !!dates[1] &&
                (formattedDate < dates[0] || formattedDate > dates[1]),
            );

            rowData[updatedDate] = data.forecast?.values[i] ?? 0;

            yDatesIds[updatedDate] = data.forecast?.ids?.[i] ?? 0;
          });

          const forecastDates = data.forecast?.dates ?? [];

          startEndYForecastDatesAux[data.serie] = {
            startDate: forecastDates.length
              ? forecastDates[0]?.replace(':00Z', '')
              : null,
            endDate: forecastDates
              ? forecastDates[forecastDates.length - 1]?.replace(':00Z', '')
              : null,
          };

          const rowNode = tableApi!.getRowNode(index.toString());

          if (rowNode) {
            rowNode.updateData({
              ...rowNode.data,
              ...rowData,
              id: index.toString(),
            });
          }

          return yDatesIds;
        }
      };

      if (!data) {
        return undefined;
      }

      if (!hasSuccessData && data && (!period[0] || !period[1])) {
        const updatedPeriod = getDefaultDateInterval();

        defaultPeriod[0] = updatedPeriod[0];
        defaultPeriod[1] = updatedPeriod[1];

        dispatch(changeWorkspaceOverviewPeriod(updatedPeriod));
      }

      if (period[0]) {
        const updatedPeriod = period;
        defaultPeriod[0] = updatedPeriod[0];
        defaultPeriod[1] = updatedPeriod[1];
      }

      const yDatesIds = updateTableColumns(defaultPeriod);

      return { yData: data, yDatesIds };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      workspaceId,
      releaseSelected?.id,
      forecastType,
      frequency,
      transformation,
      inflation,
      dateFormatter,
      dispatch,
      period,
      step?.number,
    ],
  );

  const getAllSeriesData = async () => {
    const allDates: Set<string> = new Set();
    let hasSuccessData = false;

    let promisesAggregation: any[] = [];

    if (ysInfoData) {
      if (ysInfoData.aggregation?.enable) {
        for (let j = 0; j < ysInfoData.ys.length; j++) {
          const label = ysInfoData.ys[j].y_label;
          const type = ysInfoData.ys[j].type;
          const hierarchy = ysInfoData.ys[j].hierarchy.map((aux) => aux.value);
          for (let i = 1; i <= hierarchy.length; i++) {
            promisesAggregation.push(
              getSerie(label, 'original', hierarchy.slice(0, i), type),
              getSerie(label, 'adjusted', hierarchy.slice(0, i), type),
            );

            if (promisesAggregation.length >= 200) {
              await Promise.all(promisesAggregation);
              promisesAggregation = [];
            }
          }
        }

        if (ysInfoData.market_share?.enable) {
          promisesAggregation.push(
            getSerie('', 'original', [], 'marketsize'),
            getSerie('', 'adjusted', [], 'marketsize'),
            getSerie('', 'original', [], 'sellout'),
            getSerie('', 'adjusted', [], 'sellout'),
            getSerie('', 'original', [], 'others'),
            getSerie('', 'adjusted', [], 'others'),
          );
        }
      }

      await Promise.all(promisesAggregation);

      const dataAllYs: SerieData[] = [];

      let promisesYsForecastTypeCorrect: any[] = [];
      let promisesYsOther: any[] = [];

      for (let i = 0; i < ysInfoData.ys.length; i++) {
        const label = ysInfoData.ys[i].y_label;
        promisesYsForecastTypeCorrect.push(
          getSerie(
            label,
            forecastType === 'original' ? 'original' : 'adjusted',
          ),
        );

        promisesYsOther.push(
          getSerie(
            label,
            forecastType !== 'original' ? 'original' : 'adjusted',
          ),
        );

        if (promisesYsForecastTypeCorrect.length >= 200) {
          const dataAux = (await Promise.all(
            promisesYsForecastTypeCorrect,
          )) as SerieData[];
          dataAllYs.push(...dataAux);
          promisesYsForecastTypeCorrect = [];
        }

        if (promisesYsOther.length >= 200) {
          await Promise.all(promisesYsOther);
          promisesYsOther = [];
        }
      }

      const dataAux = (await Promise.all(
        promisesYsForecastTypeCorrect,
      )) as SerieData[];
      await Promise.all(promisesYsOther);

      dataAllYs.push(...dataAux);

      const seriesDataLoadedAux: string[] = [];
      const ysDatesIdsAux: YsDatesIds = {};
      const startEndYForecastDatesAux: StartEndYForecastDates = {};

      const defaultPeriod: Dates = [null, null];

      for (let i = 0; i < dataAllYs.length; i++) {
        const label = dataAllYs[i].serie;

        const data = await getSerieData(
          i,
          allDates,
          hasSuccessData,
          startEndYForecastDatesAux,
          dataAllYs[i],
          defaultPeriod,
        );

        if (data) {
          hasSuccessData = true;
          seriesDataLoadedAux.push(label);

          if (data.yDatesIds) {
            ysDatesIdsAux[label] = data.yDatesIds;
          }
        }
      }

      setStartEndYForecastDates((state) => ({
        ...state,
        ...startEndYForecastDatesAux,
      }));
      setSeriesDataLoaded((state) => [...state, ...seriesDataLoadedAux]);
      setYsDatesIds((state) => ({ ...state, ...ysDatesIdsAux }));

      setSeriesDataIsLoading(false);
    }
  };

  useEffect(() => {
    const tableApi = tableRef?.current?.api;

    if (tableRendered) {
      setSeriesDataIsLoading(true);
      getAllSeriesData();
    }

    return () => {
      setSeriesDataLoaded([]);
      setYsDatesIds({});
      setSeriesDataIsLoading(false);
      setOriginalTableValues([]);

      if (tableColumns.length && tableApi) {
        const resetTableColumns = [...tableColumns];
        resetTableColumns[1].children = [];
        resetTableColumns[2].children = [];

        tableApi!.setGridOption('columnDefs', resetTableColumns);
      }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    frequency,
    transformation,
    forecastType,
    tableRendered,
    inflation,
    releaseSelected?.id,
    step?.number,
  ]);

  useEffect(() => {
    if (ysInfoData) {
      const columns: TableColumns = [
        { headerName: '', children: [] },
        {
          headerName: translate('Historical'),
          children: [],
        },
        {
          headerName: translate('Forecast'),
          children: [],
        },
      ];

      const rowsData: RowData[] = [];

      if (ysInfoData.market_share?.enable) {
        columns[0].children.push({
          field: 'type',
          headerName: translate('workspaceOverviewResultsType'),
          rowGroup: true,
          hide: false,
          filter: false,
          suppressMenu: true,
          pinned: 'left',
          flex: 1,
          width: 115,
          type: 'variableType',
          aggFunc: (params: IAggFuncParams) =>
            params.rowNode.id?.replace('row-group-type-', '').split('-')[0],
        });
      }

      ysInfoData.hierarchies.forEach((key) => {
        columns[0].children.push({
          field: key,
          rowGroup: true,
          hide: true,
          filter: true,
        });
      });

      if (!ysInfoData.hierarchies.length) {
        columns[0].children.push({
          field: 'y_label',
          rowGroup: true,
          hide: true,
          filter: true,
        });
      }

      ysInfoData.ys.forEach((y) => {
        const rowData: RowData = {};

        if (ysInfoData.market_share?.enable) {
          rowData.type = y.type;
        }

        ysInfoData.hierarchies.forEach((filter, index) => {
          if (y.hierarchy.length - 1 >= index) {
            rowData[filter] = y.hierarchy[index].value;
          }
        });

        rowData.y_label = y.y_label;

        rowsData.push(rowData);
      });

      if (ysInfoData.market_share?.enable && ysInfoData.series.length) {
        const marketsize: { [key: string]: string }[] = [];

        const allMarketSize = ysInfoData.ys.filter(
          (y) => y.type === 'marketsize',
        );

        allMarketSize.forEach((y) => {
          const hierarchyObj: { [key: string]: string } = {};
          y.hierarchy.forEach((hierarchy) => {
            hierarchyObj[hierarchy.name] = hierarchy.value;

            if (
              !marketsize.some(
                (marketAux) =>
                  JSON.stringify(marketAux) === JSON.stringify(hierarchyObj),
              )
            ) {
              marketsize.push({ ...hierarchyObj });
            }
          });

          if (
            !marketsize.some(
              (marketAux) =>
                JSON.stringify(marketAux) === JSON.stringify(hierarchyObj),
            )
          ) {
            marketsize.push({ ...hierarchyObj });
          }
        });

        const sellout: { [key: string]: string }[] = [];

        if (ysInfoData.market_share?.enable) {
          const allSellout = ysInfoData.ys.filter((y) => y.type === 'sellout');

          allSellout.forEach((y) => {
            const hierarchyObj: { [key: string]: string } = {};
            y.hierarchy.forEach((hierarchy) => {
              hierarchyObj[hierarchy.name] = hierarchy.value;

              if (
                !sellout.some(
                  (selloutAux) =>
                    JSON.stringify(selloutAux) === JSON.stringify(hierarchyObj),
                )
              ) {
                sellout.push({ ...hierarchyObj });
              }
            });

            if (
              !sellout.some(
                (selloutAux) =>
                  JSON.stringify(selloutAux) === JSON.stringify(hierarchyObj),
              )
            ) {
              sellout.push({ ...hierarchyObj });
            }
          });
        }

        const marketShareAux = marketsize.filter((marketAux) =>
          sellout.find(
            (selloutAux) =>
              JSON.stringify(marketAux) === JSON.stringify(selloutAux),
          ),
        );

        const marketShareFiltered = marketShareAux.filter((marketAux) => {
          const string1 = JSON.stringify(Object.entries(marketAux)).replace(
            ']]',
            '',
          );

          return !marketShareAux.some((marketAux1) => {
            const string2 = JSON.stringify(Object.entries(marketAux1)).replace(
              ']]',
              '',
            );

            return string2 !== string1 && string2.includes(string1);
          });
        });

        const marketShareNotFiltered = marketShareAux.filter((marketAux) => {
          const string1 = JSON.stringify(Object.entries(marketAux)).replace(
            ']]',
            '',
          );

          return marketShareAux.some((marketAux1) => {
            const string2 = JSON.stringify(Object.entries(marketAux1)).replace(
              ']]',
              '',
            );

            return string2 !== string1 && string2.includes(string1);
          });
        });

        const marketShareStateAux: MarketShare[] = [
          { isAggregation: true },
          ...marketShareNotFiltered.map((marketAux) => ({
            ...marketAux,
            isAggregation: true,
          })),
        ];

        if (marketShareFiltered.length) {
          marketShareFiltered.forEach((marketShareAux1) => {
            const objAux = { ...marketShareAux1 };
            const lastKey = Object.keys(objAux).at(-1)!;

            const yLabel = objAux[lastKey];

            delete objAux[lastKey];

            marketShareStateAux.push({
              ...objAux,
              y_label: yLabel,
              isAggregation: false,
              rowId: rowsData.length,
            });

            rowsData.push({
              ...objAux,
              type: 'marketshare',
              y_label: yLabel,
            });
          });
        }

        setMarketShare(marketShareStateAux);
      }
      setTableData(rowsData);
      setTableColumns(columns);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ysInfoData]);

  useEffect(() => {
    let interval: NodeJS.Timeout | null = null;
    if (!editionExpired && editionModeEnabled) {
      interval = setInterval(() => setEditionExpired(true), ms('5 min'));
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [editionExpired, editionModeEnabled]);

  useEffect(() => {
    if (isSaving) {
      let editingCell = tableRef.current?.api.getEditingCells() ?? [];

      while (editingCell.length > 0) {
        sleep(100);

        editingCell = tableRef.current?.api.getEditingCells() ?? [];
      }

      sleep(100);

      if (!invalidValues) {
        handleSaveChanges();
      } else {
        setIsSaving(false);
        dispatch(changeEditionModeEnabled(false));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSaving]);

  useEffect(() => {
    const formatDateField = (columnChildren: ColDef[]) =>
      columnChildren.map((column: ColDef) => {
        if (!column.field) return { ...column };

        return {
          ...column,
          headerName: dateFormatter(column.field),
        };
      });

    const updateColumns = (columns: ColDef[]) =>
      columns
        .map((column) => {
          if (column.field) {
            const date = new Date(column.field);

            if (
              period[0] &&
              period[1] &&
              date >= period[0] &&
              date <= period[1]
            ) {
              column.hide = false;
            } else {
              column.hide = true;
            }
          }

          return { ...column };
        })
        .sort((a, b) => {
          if (a?.field && b?.field) {
            return new Date(a.field).getTime() - new Date(b.field).getTime();
          }
          return 0;
        });

    const translateGroupColumn = () => {
      tableRef.current?.api.setGridOption('autoGroupColumnDef', {
        ...autoGroupColumnDef,
        headerName: translate('workspaceOverviewResultsGroup'),
      });
    };

    const translateColumns = () => {
      const columns = tableRef.current?.api.getGridOption('columnDefs');

      if (columns) {
        if (ysInfoData?.market_share?.enable) {
          (columns[0] as ColGroupDef).children[0].headerName = translate(
            'workspaceOverviewResultsType',
          );
        }

        const historical = columns[1] as ColGroupDef;
        const forecast = columns[2] as ColGroupDef;

        if (historical) {
          historical.headerGroupComponent = () => (
            <div
              data-testid="historical-header"
              className="historical-forecast-header"
            >
              <LockSimple data-testid="historical-icon" />
              <p>{translate('Historical')}</p>
            </div>
          );

          historical.children = formatDateField(historical.children);
          historical.children = updateColumns(historical.children);
        }

        if (forecast) {
          forecast.headerGroupComponent = () => (
            <div
              data-testid="forecast-header"
              className="historical-forecast-header"
            >
              <PencilSimple data-testid="forecast-icon" />
              <p>{translate('Forecast')}</p>
            </div>
          );

          forecast.children = formatDateField(forecast.children);
          forecast.children = updateColumns(forecast.children);
        }
      }

      tableRef.current?.api.setGridOption('columnDefs', columns);
    };

    if (tableRendered) {
      translateGroupColumn();
      translateColumns();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [translate, tableRendered, ysInfoData?.market_share?.enable]);

  useEffect(() => {
    if (tableRendered) {
      let hasColumn = false;

      const columns = tableRef.current?.api.getGridOption('columnDefs');

      const columnsHide: string[] = [];
      const columnsVisible: string[] = [];

      columns?.forEach((col, index) => {
        if (col.headerName) {
          const column = col as ColGroupDef;

          column?.children?.forEach(({ field }: ColDef, i) => {
            if (field) {
              hasColumn = true;

              const date = new Date(field);

              if (
                period[0] &&
                period[1] &&
                date >= period[0] &&
                date <= period[1]
              ) {
                ((columns[index] as ColGroupDef).children[i] as ColDef).hide =
                  false;
                columnsVisible.push(field);
              } else {
                ((columns[index] as ColGroupDef).children[i] as ColDef).hide =
                  true;
                columnsHide.push(field);
              }
            }
          });
        }
      });

      if (hasColumn) {
        tableRef.current?.api.setGridOption('columnDefs', columns);
      }
    }
  }, [tableRendered, period, releaseSelected?.id]);

  useEffect(() => {
    if (
      tableRef.current &&
      !originalTableValues.length &&
      !seriesDataIsLoading &&
      seriesDataLoaded.length &&
      ysInfoData
    ) {
      const values: OriginalTableValues[] = [];

      tableRef?.current?.api?.forEachNode((node) => {
        values.push({
          id: node.id,
          aggData: { ...node.aggData },
          data: { ...node.data },
        });
      });

      setOriginalTableValues(values);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    tableColumns,
    tableRef,
    originalTableValues,
    dispatch,
    seriesDataLoaded,
    ysInfoData,
    forecastType,
    inflation,
    seriesDataIsLoading,
  ]);

  useEffect(() => {
    if (
      step &&
      (step.status === 'awaiting_approval' || step.status === 'approved') &&
      editionModeEnabled
    ) {
      dispatch(changeEditionModeEnabled(false));
    }

    if (step && step.status !== 'awaiting_approval' && reviewModeEnabled) {
      dispatch(changeReviewModeEnabled(false));
    }
  }, [step, editionModeEnabled, dispatch, reviewModeEnabled]);

  useEffect(() => {
    const updateStepStatusToAdjusting = async () => {
      try {
        await apiWorkspace.put<Step>(
          `/workspaces/${workspaceId}/releases/${releaseSelected?.id}/steps/${step?.number}`,
          { status: 'adjusting' },
        );

        if (step) {
          dispatch(
            changeWorkspaceOverviewStep({
              ...step,
              status: 'adjusting',
              selectedStatus: 'adjusting',
            }),
          );
        }
        // eslint-disable-next-line no-empty
      } catch {}
    };

    const checkIfStatusHasBeenUpdated = async () => {
      await sleep(ms('15s'));

      queryClient.refetchQueries({
        queryKey: ['workspace', workspaceId, 'releases', releaseSelected?.id],
        exact: true,
      });
    };

    const isRemovingFirstStep =
      releaseData?.status === 'removing_step' &&
      releaseData?.data?.steps?.[0]?.status === 'baseline';

    if (
      (isDiscardingAdjustments ||
        releaseData?.status === 'discarding_step' ||
        isRemovingFirstStep) &&
      !releaseIsLoading
    ) {
      if (releaseData?.status === 'discarding_step' || isRemovingFirstStep) {
        setTableRendered(false);
        setIsDiscardingAdjustments(true);
        checkIfStatusHasBeenUpdated();
      } else {
        setIsDiscardingAdjustments(false);

        if (releaseData?.data.steps?.[0]?.status !== 'baseline') {
          updateStepStatusToAdjusting();
        }

        const lastStep = releaseData?.data.steps?.length;

        ysInfoData?.ys.forEach(({ y_label }) => {
          queryClient.refetchQueries([
            'workspace',
            'series data',
            workspaceId,
            releaseSelected?.id,
            y_label,
            'adjusted',
            lastStep ?? 1,
          ]);
        });

        invalidateChartDataAndAggregationMarketShareData(lastStep);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isDiscardingAdjustments,
    releaseIsLoading,
    releaseData?.status,
    workspaceId,
    releaseSelected?.id,
  ]);

  async function handleUpdate() {
    if (ysInfoData) {
      for (let i = 0; i < ysInfoData.ys.length; i++) {
        await queryClient.removeQueries([
          'workspace',
          'series data',
          workspaceId,
          releaseSelected?.id,
          ysInfoData.ys[i].y_label,
          'adjusted',
          step?.number ?? 1,
        ]);
      }
    }

    await invalidateChartDataAndAggregationMarketShareData();

    const tableApi = tableRef?.current?.api;

    setSeriesDataLoaded([]);
    setYsDatesIds({});
    setSeriesDataIsLoading(false);
    setOriginalTableValues([]);

    if (tableColumns.length && tableApi) {
      const resetTableColumns = [...tableColumns];
      resetTableColumns[1].children = [];
      resetTableColumns[2].children = [];

      tableApi!.setGridOption('columnDefs', resetTableColumns);
    }

    if (tableRendered) {
      setSeriesDataIsLoading(true);
      getAllSeriesData();
    }

    if (adjustedStepLogData?.records && step?.number !== undefined) {
      dispatch(
        changeLastLogTime({
          step: step.number,
          value: new Date(adjustedStepLogData.records[0].timestamp).getTime(),
        }),
      );
    }
    setButtonUpdateStatus('closed');
  }

  useEffect(() => {
    if (releaseData?.data.approval_flow?.enable) {
      if (
        step?.selectedStatus === 'baseline' &&
        step.number === 1 &&
        step.status !== 'baseline'
      ) {
        dispatch(changeWorkspaceOverviewForecast('original'));
      } else {
        dispatch(changeWorkspaceOverviewForecast('adjusted'));
      }
    }
  }, [step, dispatch, releaseData?.data.approval_flow]);

  useEffect(() => {
    if (
      isDiscardingAdjustments &&
      step?.number === releaseData?.data.steps?.length
    ) {
      setTableRendered(false);
    }
  }, [step?.number, isDiscardingAdjustments, releaseData?.data.steps]);

  const columnDefs = useMemo(() => {
    if (!tableColumns) return [];

    return tableColumns;
  }, [tableColumns]);

  const selectedFilterInflation =
    user.language === 'pt-br'
      ? `${translate('value')} ${inflation}`
      : `${transformUppercaseFirstLetter(inflation)} ${translate(
          'value',
        ).toLowerCase()}`;

  const selectedFilters: SelectedFilterOptions[] = [
    {
      type: 'frequency',
      selected: getSelectedFrequencyAndPeriod(
        translate(frequency),
        period,
        user.language,
      ),
    },
    { type: 'transformation', selected: translate(transformation) },
  ];

  const hasForecastLabel =
    workspaceFrequency === 'monthly' &&
    !releaseData?.data.approval_flow?.enable &&
    releaseSelected?.id !== releasePreview;

  hasForecastLabel &&
    selectedFilters.push({
      type: 'other',
      id: 'forecast',
      icon: <PencilSimple />,
      selected: translate(
        forecastType === 'adjusted'
          ? 'workspaceProjectionsMostRecent'
          : forecastType,
      ),
    });

  const hasInflationLabel = releaseSelected?.data.ys.some((y) => y.is_inflated);

  hasInflationLabel &&
    selectedFilters.push({
      type: 'inflation',
      selected: selectedFilterInflation,
    });

  const isWaitingApproval = step?.status === 'awaiting_approval';
  const isApproved = step?.status === 'approved';
  const isAdjusting = step?.status === 'adjusting';
  const isManager = userRole === 'manager';
  const isPreview = step?.number === 1 && step.selectedStatus === 'baseline';

  const hasDisapprovalMessage = !!step?.disapproval_messages?.length;
  const isDisapproved = !!isAdjusting && !!hasDisapprovalMessage;

  const showFlowMessage =
    !isPreview &&
    ((isWaitingApproval && isManager) || isApproved || isDisapproved);
  const flowMessageInformation: LogRecord | undefined =
    isWaitingApproval || isApproved || isDisapproved
      ? updatedStepLogData?.records[0]
      : undefined;

  const waitingApprovalMessage =
    flowMessageInformation?.data.awaiting_approval_message?.[0];
  const approvedMessage = flowMessageInformation?.data.approval_message?.[0];
  const disapprovedMessage =
    flowMessageInformation?.data.disapproval_messages?.[0];
  const flowMessage = isWaitingApproval
    ? waitingApprovalMessage
    : isApproved
    ? approvedMessage
    : disapprovedMessage;

  const isShowingAdjustedData = forecastType === 'adjusted';

  const hasNoAdjustments =
    new Date(updatedStepLogData?.records?.[0]?.timestamp ?? '5000-01-01') >
    new Date(adjustedStepLogData?.records?.[0]?.timestamp ?? '1000-01-01');

  const tableStatus: TableStatus = !isShowingAdjustedData
    ? null
    : editionModeEnabled
    ? 'editing'
    : reviewModeEnabled
    ? 'reviewing'
    : step?.status === 'awaiting_approval'
    ? 'awaiting_approval'
    : step?.status === 'approved'
    ? 'approved'
    : hasNoAdjustments && isDisapproved
    ? 'disapproved'
    : null;

  const showAwaitingApprovalTag =
    step?.status === 'awaiting_approval' && !isManager && isShowingAdjustedData;

  const showApprovedTag = step?.status === 'approved' && isShowingAdjustedData;

  const showReviewScenarioButton =
    step?.status === 'awaiting_approval' &&
    isManager &&
    isShowingAdjustedData &&
    !reviewModeEnabled;

  const showMakeAdjustmentsButton =
    hasNoAdjustments &&
    isDisapproved &&
    !editionModeEnabled &&
    isShowingAdjustedData;

  const showEditionModeButtons =
    frequency === 'original' &&
    workspaceFrequency === 'monthly' &&
    transformation === 'level' &&
    isShowingAdjustedData &&
    (!step || isAdjusting || step.status === 'baseline') &&
    !showMakeAdjustmentsButton;

  const showDisapproveApproveButtons =
    reviewModeEnabled && isShowingAdjustedData;

  const showDiscardAdjustmentsAndSubmitApprovalButton =
    step?.status === 'adjusting' &&
    !editionModeEnabled &&
    isShowingAdjustedData &&
    !showMakeAdjustmentsButton;

  const workspaceIsPreview = releaseSelected?.id === releasePreview;

  const editionModeDisabled =
    workspaceIsPreview ||
    loadingEnableEdition ||
    seriesDataIsLoading ||
    buttonUpdateStatus === 'opened';

  const discardAdjustmentsDisabled =
    hasNoAdjustments || buttonUpdateStatus === 'opened';

  const submitForApprovalDisabled = buttonUpdateStatus === 'opened';

  const reviewScenarioDisabled = buttonUpdateStatus === 'opened';

  return (
    <Container
      data-testid="results-description-card"
      className="containerLinear"
    >
      <Card
        textCard={translate('workspaceOverviewResultsTitle')}
        textDescription={translate('workspaceOverviewResultsDescription')}
        style={{ width: showFlowMessage ? 'calc(100% - 27rem)' : '100%' }}
      />

      <ButtonUpdate
        id="button-update-data"
        onClick={handleUpdate}
        status={buttonUpdateStatus}
        data-testid="button-update-data"
      >
        <ArrowsClockwise size={16} />
        {translate('workspaceOverviewUpdateData')}
      </ButtonUpdate>

      <HeaderRightContent>
        {showFlowMessage && (
          <FlowMessage
            isLoading={updatedStepLogIsLoading}
            message={flowMessage}
            author={flowMessageInformation?.user}
            dateTime={flowMessageInformation?.timestamp}
          />
        )}
      </HeaderRightContent>

      <SelectedFiltersExportContainer>
        <SelectedFilters filters={selectedFilters} />

        <DownloadButton
          buttonType="secondary"
          icon={<DownloadSimple />}
          onClick={() => handleDownloadTable(true)}
          disabled={
            seriesDataIsLoading ||
            !seriesDataLoaded.length ||
            editionModeEnabled
          }
          data-testid="download-results-button"
          data-tooltip-id="results-table"
          data-tooltip-content={
            seriesDataIsLoading
              ? translate('workspaceOverviewResultsLoadingData')
              : editionModeEnabled
              ? translate('workspaceOverviewResultsEditionBlockDownload')
              : undefined
          }
        >
          {translate('export')}
        </DownloadButton>
      </SelectedFiltersExportContainer>

      {ysInfoIsError ? (
        <Status
          type="error"
          title={translate('workspaceOverviewResultsErrorTitle')}
          description={translate('workspaceOverviewResultsErrorDescription')}
        />
      ) : ysInfoIsLoading || ysInfoIsFetching || !tableColumns.length ? (
        // eslint-disable-next-line react/jsx-indent
        <ContainerSkeleton
          data-testid="loading-results-comparison"
          style={{ height: '22rem' }}
        />
      ) : isDiscardingAdjustments &&
        step?.number === releaseData?.data.steps?.length ? (
        // eslint-disable-next-line react/jsx-indent
        <DiscardingAdjustmentsContainer data-testid="loading-discarding-adjustments">
          <ContainerSkeleton style={{ height: 'fit-content' }} />

          <p>
            {translate('workspaceOverviewPlanningFlowDiscardingAdjustments')}
          </p>
        </DiscardingAdjustmentsContainer>
      ) : (
        <TableContainer data-testid="results-table" status={tableStatus}>
          {workspaceFrequency === 'monthly' && (
            <TableButtonsContainer>
              {showDisapproveApproveButtons ? (
                <>
                  <Button
                    buttonType="secondary"
                    icon={<X />}
                    onClick={() => setShowDisapproveModal(true)}
                    data-testid="button-disapprove"
                    className="button-disapprove"
                  >
                    {translate('workspaceOverviewPlanningFlowDisapprove')}
                  </Button>
                  <Button
                    buttonType="primary"
                    icon={<CheckCircle />}
                    onClick={() => setShowApproveModal(true)}
                    data-testid="button-approve"
                    className="button-approve"
                  >
                    {translate('workspaceOverviewPlanningFlowApprove')}
                  </Button>
                </>
              ) : showAwaitingApprovalTag ? (
                <Tag
                  data-testid="container-waiting-approval"
                  className="waiting-approval-tag"
                >
                  {translate('workspaceOverviewPlanningFlowWaitingApproval')}
                </Tag>
              ) : showReviewScenarioButton ? (
                <Button
                  buttonType="primary"
                  icon={<CheckCircle />}
                  onClick={handleStartReview}
                  disabled={reviewScenarioDisabled}
                  data-testid="button-review-scenario"
                  className="button-review-scenario"
                  data-tooltip-id="results-table"
                  data-tooltip-content={
                    buttonUpdateStatus === 'opened'
                      ? translate(
                          'workspaceOverviewUpdateDataToEnableReviewScenario',
                        )
                      : undefined
                  }
                >
                  {translate('workspaceOverviewPlanningFlowReviewScenario')}
                </Button>
              ) : showApprovedTag ? (
                <Tag data-testid="container-approved" className="approved-tag">
                  {translate('workspaceOverviewPlanningFlowApproved')}
                </Tag>
              ) : (
                showMakeAdjustmentsButton && (
                  <Button
                    buttonType="primary"
                    icon={<PencilSimple />}
                    onClick={handleEnableEdition}
                    loading={loadingEnableEdition}
                    disabled={loadingEnableEdition || seriesDataIsLoading}
                    data-testid="button-new-adjustments"
                    className="button-new-adjustments"
                  >
                    {translate('workspaceOverviewPlanningFlowMakeAdjustments')}
                  </Button>
                )
              )}

              {showEditionModeButtons && (
                <>
                  {!editionModeEnabled ? (
                    <Button
                      buttonType="secondary"
                      icon={<PencilSimple />}
                      onClick={handleEnableEdition}
                      loading={loadingEnableEdition}
                      disabled={editionModeDisabled}
                      data-testid="enable-edition-button"
                      className="enable-edition-button"
                      data-tooltip-id="results-table"
                      data-tooltip-content={
                        buttonUpdateStatus === 'opened'
                          ? translate(
                              'workspaceOverviewUpdateDataToEnableEditingMode',
                            )
                          : workspaceIsPreview
                          ? translate(
                              'workspaceOverviewResultsEditionDisabledTooltip',
                            )
                          : seriesDataIsLoading
                          ? translate('workspaceOverviewResultsLoadingData')
                          : undefined
                      }
                    >
                      {translate('workspaceOverviewResultsEnableEditMode')}
                    </Button>
                  ) : (
                    <>
                      <Button
                        buttonType="secondary"
                        icon={<X />}
                        onClick={handleDiscardChanges}
                        data-testid="discard-edition-button"
                        className="discard-edition-button"
                      >
                        {translate('workspaceOverviewResultsDiscardEdition')}
                      </Button>
                      <Button
                        buttonType="primary"
                        icon={<FloppyDisk />}
                        onClick={() => setIsSaving(true)}
                        loading={isSaving}
                        disabled={invalidValues > 0 || isSaving}
                        data-testid="save-edition-button"
                        className="save-edition-button"
                      >
                        {translate('workspaceOverviewResultsSaveEdition')}
                      </Button>
                    </>
                  )}
                </>
              )}

              {showDiscardAdjustmentsAndSubmitApprovalButton && (
                <>
                  <Button
                    buttonType="secondary"
                    icon={<Trash />}
                    onClick={() => setShowDiscardAdjustmentsConfirmation(true)}
                    disabled={discardAdjustmentsDisabled}
                    data-testid="button-discard-adjustments"
                    className="button-discard-adjustments"
                    data-tooltip-id="results-table"
                    data-tooltip-content={
                      buttonUpdateStatus === 'opened'
                        ? translate(
                            'workspaceOverviewUpdateDataToEnableDiscardAdjustments',
                          )
                        : hasNoAdjustments
                        ? translate(
                            'workspaceOverviewPlanningFlowNoAdjustments',
                          )
                        : undefined
                    }
                  >
                    {translate(
                      'workspaceOverviewPlanningFlowDiscardAdjustments',
                    )}
                  </Button>

                  <SubmitApprovalContainer>
                    <Button
                      buttonType="secondary"
                      icon={<PaperPlaneTilt />}
                      onClick={() =>
                        setShowRequestApproval(!showRequestApproval)
                      }
                      disabled={submitForApprovalDisabled}
                      data-testid="button-submit-for-approval"
                      className="button-submit-for-approval"
                      data-tooltip-id="results-table"
                      data-tooltip-content={
                        buttonUpdateStatus === 'opened'
                          ? translate(
                              'workspaceOverviewUpdateDataToEnableSubmitForApproval',
                            )
                          : undefined
                      }
                    >
                      {translate('workspaceOverviewPlanningFlowSubmitApproval')}
                    </Button>

                    <RequestApproval
                      visible={showRequestApproval}
                      lockEdition={lockEdition}
                      onRequestApproval={updateStepStatus}
                      closeRequestApproval={() => setShowRequestApproval(false)}
                    />
                  </SubmitApprovalContainer>
                </>
              )}
            </TableButtonsContainer>
          )}

          {invalidValues > 0 && (
            <TableError visible data-testid="invalid-values-error">
              <Warning weight="fill" />
              <p>{translate('workspaceOverviewResultsInvalidValue')}</p>
            </TableError>
          )}

          <AgGridTable
            animateRows={false}
            tableRef={tableRef}
            rowData={tableData}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            autoGroupColumnDef={autoGroupColumnDef}
            groupAllowUnbalanced
            columnTypes={{
              defaultConfig: {
                aggFunc,
                editable: (params: EditableCallbackParams) =>
                  editable(
                    params.data.y_label,
                    new Date(params.colDef.field ?? ''),
                  ),
                cellEditor: 'agNumberCellEditor',
                valueFormatter: (params: ValueFormatterParams) =>
                  params.value
                    ? addThousandSeparator(params.value, user.language)
                    : '-',
                cellClass: (params: CellClassParams) => {
                  if (
                    params.colDef.type?.includes('forecastValue') &&
                    !params.data?.y_label
                  ) {
                    return 'forecast-values';
                  }

                  return cellClassName(
                    params.data?.y_label,
                    new Date(params.colDef.field ?? ''),
                  );
                },
                cellRenderer: (params: ICellRendererParams) => {
                  const baselineValue = getOriginalValue(
                    params.node,
                    params.colDef?.field ?? '',
                  );

                  if (
                    (transformation === 'variation' &&
                      params.node?.allLeafChildren?.length &&
                      ysInfoData?.aggregation?.enable) ||
                    (params.value !== baselineValue && params.value === null)
                  ) {
                    const rowNode = params.api.getRowNode(
                      params.node.id ?? '',
                    )!;

                    rowNode.setDataValue(
                      params.colDef?.field ?? '',
                      baselineValue,
                    );
                  }

                  const isAggregationForecast =
                    params.colDef?.type?.includes('forecastValue') &&
                    !params.data?.y_label;

                  const adjustedValue =
                    transformation === 'variation' &&
                    params.node?.allLeafChildren?.length &&
                    ysInfoData?.aggregation?.enable
                      ? getAdjustedVariationValue(
                          params.node,
                          params.colDef?.field ?? '',
                        )
                      : params.value;

                  const id = `${params.node.id}-${params.colDef?.field}` ?? '';

                  const isMarketShare =
                    id.startsWith('row-group-type-marketshare') ||
                    !!params.node?.parent?.id?.startsWith(
                      'row-group-type-marketshare',
                    );

                  return (
                    <ValueCell
                      id={id}
                      baselineValue={baselineValue}
                      adjustedValue={adjustedValue}
                      isForecast={
                        isAggregationForecast ||
                        isForecast(
                          params.data?.y_label,
                          new Date(params.colDef?.field ?? ''),
                        )
                      }
                      showValueError={editionModeEnabled}
                      isMarketShare={isMarketShare}
                      showBaselineValue={
                        (reviewModeEnabled && isShowingAdjustedData) ||
                        editionModeEnabled ||
                        (isApproved && isShowingAdjustedData) ||
                        (isWaitingApproval && !isManager)
                      }
                    />
                  );
                },
              },
              historicalValue: {},
              forecastValue: {},
              variableType: {
                cellRenderer: (params: ICellRendererParams) => {
                  let updatedValue = params.value;

                  if (params?.value?.startsWith('marketshare')) {
                    updatedValue = 'Market Share (%)';
                  }
                  if (params?.value?.startsWith('marketsize')) {
                    updatedValue = 'Market Size';
                  }
                  if (params?.value?.startsWith('sellout')) {
                    updatedValue = 'Sell Out';
                  }
                  if (params?.value?.startsWith('others')) {
                    updatedValue = translate('workspaceOverviewOthers');
                  }

                  let id = params.node.id;

                  if (!!Number(id) || id === '0') {
                    id = `variable-${params.node.data.y_label}`;
                  }

                  return (
                    <div
                      data-testid={`cell-row-${id
                        ?.replace('row-group-type-', '')
                        .replace('others-', '')
                        .replace('sellout-', '')
                        .replace('marketsize-', '')
                        .replace('marketshare-', '')}-column-type`}
                    >
                      {updatedValue}
                    </div>
                  );
                },
                cellClass: (params: CellClassParams) => {
                  if (params?.value?.startsWith('marketshare')) {
                    return 'marketshare-cell';
                  }
                  return '';
                },
              },
            }}
            onCellValueChanged={(params) =>
              handleEditedValue(
                params.data.y_label,
                params.colDef.field ?? '',
                params.newValue,
                params.oldValue,
                params.node.id ?? '',
              )
            }
            onFirstDataRendered={() => setTableRendered(true)}
            excelStyles={excelStyle}
            groupRemoveSingleChildren={!ysInfoData?.hierarchies.length}
            checkDomLayoutType={tableRendered}
            groupDefaultExpanded={
              !ysInfoData?.aggregation?.enable
                ? -1
                : ysInfoData.market_share?.enable
                ? 1
                : 0
            }
            groupAggFiltering
            getRowHeight={(params) => {
              if (variablesTypeRowId.includes(params.node.id ?? '')) return 0;
              return 44;
            }}
            suppressGroupRowsSticky
          />
        </TableContainer>
      )}

      <Tooltip id="results-table" className="customTooltipTheme" />

      {(!!showUserIsEditingModal || showWorkspaceIsPublishing) && (
        <NoPermissionToEditModal
          setVisible={() => {
            setShowUserIsEditingModal('');
            setShowWorkspaceIsPublishing(false);
          }}
          emailEditing={showUserIsEditingModal}
          errorDescription={
            editionModeEnabled
              ? translate(
                  'workspaceOverviewResultsEditionLockedDescription',
                ).replace('XXX', showUserIsEditingModal)
              : showWorkspaceIsPublishing
              ? translate('workspaceOverviewResultsWorkspaceIsPublishing')
              : undefined
          }
        />
      )}

      {editionExpired && editionModeEnabled && (
        <SessionExpiredModal
          handleBlockEdition={handleEnableEdition}
          loadingBlockEdition={loadingEnableEdition}
          handleDiscardChanges={handleDiscardChanges}
        />
      )}

      {unsavedYs.length > 0 && (
        <UnsavedYsModal ys={unsavedYs} setVisible={() => setUnsavedYs([])} />
      )}

      {showApproveModal && (
        <ApproveOrDisapproveModal
          approveOrDisapprove="approve"
          onConfirm={handleApproveReview}
          onCancel={() => setShowApproveModal(false)}
        />
      )}

      {showDisapproveModal && (
        <ApproveOrDisapproveModal
          approveOrDisapprove="disapprove"
          onConfirm={handleDisapproveReview}
          onCancel={() => setShowDisapproveModal(false)}
        />
      )}

      {showDiscardAdjustmentsConfirmation && (
        <ActionConfirmationModal
          setVisible={setShowDiscardAdjustmentsConfirmation}
          title={translate(
            'workspaceOverviewDiscardAdjustmentsConfirmationModalTitle',
          )}
          description={translate(
            step?.number === 1
              ? 'workspaceOverviewDiscardAdjustmentsConfirmationModalDescriptionFirstStep'
              : 'workspaceOverviewDiscardAdjustmentsConfirmationModalDescriptionOtherStep',
          )}
          onConfirm={handleDiscardAllAdjustments}
        />
      )}

      {showUpdateStatusError && (
        <Modal
          visible
          setVisible={setShowUpdateStatusError}
          style={{ width: '30rem' }}
          dataCy="modal-update-status-error"
        >
          <Status
            type="cloudWarning"
            title={translate('workspaceOverviewPlanningFlowUpdateErrorTitle')}
            description={translate(
              'workspaceOverviewPlanningFlowUpdateErrorDescription',
            )}
            isModal
          />

          <ModalFooter>
            <Button
              buttonType="primary"
              onClick={() => setShowUpdateStatusError(false)}
            >
              Ok
            </Button>
          </ModalFooter>
        </Modal>
      )}
    </Container>
  );
};
