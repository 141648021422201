import React, { useContext, useEffect, useState } from 'react';

import { AxiosError } from 'axios';
import { format, isMatch, parse } from 'date-fns';
import ms from 'ms';
import {
  DownloadSimple,
  FileText,
  FileX,
  MagnifyingGlass,
  Trash,
} from 'phosphor-react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
import { ButtonCopyToClipboard } from 'src/components/ButtonCopyToClipboard';
import { ContainerMaintenance } from 'src/components/ContainerMaintenance';
import { ContainerSkeleton } from 'src/components/ContainerSkeleton';
import { Head } from 'src/components/Head';
import { Input } from 'src/components/Input';
import { MenuContainer, MenuOption } from 'src/components/Menu/styles';
import { FailedModal } from 'src/components/Modal/Failed';
import { ModalLoading } from 'src/components/Modal/Loading';
import { WarningModal } from 'src/components/Modal/Warning';
import { PaginationV2 } from 'src/components/Pagination/V2';
import { Status } from 'src/components/Status';
import { TableV2 } from 'src/components/TableV2';
import { FavoriteStatus } from 'src/feature-store/components/FavoriteStatus';
import { FeatureStoreSidebarContext } from 'src/feature-store/Contexts/NavigationContext';
import { setInfoToViewTheSerie } from 'src/feature-store/redux/reducers/SerieToView';
import api from 'src/feature-store/service/api';
import { useDebounce } from 'src/hooks/useDebounce';
import { useFormatDateLanguage } from 'src/hooks/useFormatDateLanguage';
import { DataError } from 'src/interface/axios';
import { RootState } from 'src/redux/store';
import apiBFF from 'src/service/api';
import light from 'src/styles/themes/light';
import { dateToIsoStringWithTimezone } from 'src/utils/dates/dateToIsoStringWithTimezone';
import { getTextWidth } from 'src/utils/getTextWidth';
import { getTextWidthWithLineBreaks } from 'src/utils/getTextWidthWithLineBreaks';

import { CreateGroupModal } from '../Groups/components/CreateGroupModal';
import { Group } from '../Groups/types';
import { DeleteSeriesModal } from './components/DeleteSeriesModal';
import { FavoriteTypeHeader } from './components/FavoriteTypeHeader';
import { TableLoading } from './components/Loading';
import {
  ButtonIcon,
  ButtonsContainer,
  Container,
  Content,
  ContentButtonExport,
  Description,
  ExportButton,
  HeaderContainer,
  HeaderContent,
  HeaderSkeletonContainer,
  MenuOverlay,
  RoundedButtonsContainer,
} from './styles';
import {
  GroupSeries,
  IResponseHeadersDownload,
  Serie,
  SummaryProps,
} from './types';

type ParamsProps = {
  id: string;
};

export type ErrorObject = {
  title?: string;
  description?: string;
  cloudIcon?: boolean;
};

const QUANTITY_ITEMS_PAGE = 8;
const QUANTITY_EXPORT = 150;

export const GroupView: React.FC = () => {
  const navigate = useNavigate();
  const { id } = useParams<ParamsProps>();

  const { user } = useSelector((state: RootState) => state.auth);
  const dispatch = useDispatch();

  const translateFormat = useFormatDateLanguage();

  const [page, setPage] = useState(1);
  const [serieToDelete, setSerieToDelete] = useState<string>();
  const [createFavoriteModalIsVisible, setCreateFavoriteModalIsVisible] =
    useState(false);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [failModalVisible, setFailModalVisible] = useState(false);
  const [search, setSearch] = useState<string>('');
  const [searchError, setSearchError] = useState<string>('');
  const [failedModalInfo, setFailedModalInfo] = useState<ErrorObject>();
  const [failedModalVisible, setFailedModalVisible] = useState(false);
  const [showOptionsDownload, setShowOptionsDownload] =
    useState<boolean>(false);
  const [downloadLoading, setDownloadLoading] = useState<boolean>(false);
  const [lastSearch, setLastSearch] = useState('');
  const [summaryModalVisible, setSummaryModalVisible] = useState(false);
  useState(false);

  const { debouncedValue: searchDebouced, isLoadingDebounce } =
    useDebounce(search);

  const { language, isFreemium } = useSelector(
    (state: RootState) => state.auth.user,
  );

  const { t: translate } = useTranslation();

  const {
    setterIdFavoriteViewingPage,
    openIndicatorFilters,
    openPremiumFilters,
    setterFavoriteType,
    favoriteType,
  } = useContext(FeatureStoreSidebarContext);

  const {
    data: groupData,
    isLoading: isLoadingGroup,
    isError: isGroupErrored,
  } = useQuery(
    ['group', id],
    async () => {
      const { data } = await api.get<Group>(`/groups/${id}`);

      if (data) {
        setterFavoriteType(data.category);
      }

      return data;
    },
    {
      enabled: !!id && id !== 'empty',
      staleTime: 5000 * 60,
    },
  );

  const {
    data: groupSeriesData,
    isLoading: isLoadingGroupSeries,
    isFetching: isFetchingGroupSeries,
    isError: isGroupSeriesErrored,
  } = useQuery(
    ['group series', id, page, searchDebouced],
    async () => {
      let searchQuery = searchDebouced;
      if (isMatch(searchDebouced, translateFormat)) {
        searchQuery = format(
          parse(searchDebouced, translateFormat, new Date()),
          'yyyy-MM-dd',
        );
      }

      const { data } = await api.get<GroupSeries>(
        searchDebouced.length >= 3
          ? isMatch(searchQuery, 'yyyy-MM-dd')
            ? `/groups/${id}/series?last_updated=${dateToIsoStringWithTimezone(
                new Date(`${searchQuery}T00:00`),
              )}&skip=${
                (page - 1) * QUANTITY_ITEMS_PAGE
              }&limit=${QUANTITY_ITEMS_PAGE}`
            : `/groups/${id}/series?name=${encodeURIComponent(
                searchDebouced,
              )}&source=${encodeURIComponent(
                searchDebouced,
              )}&region=${encodeURIComponent(searchDebouced)}&skip=${
                (page - 1) * QUANTITY_ITEMS_PAGE
              }&limit=${QUANTITY_ITEMS_PAGE}`
          : `/groups/${id}/series?skip=${
              (page - 1) * QUANTITY_ITEMS_PAGE
            }&limit=${QUANTITY_ITEMS_PAGE}`,
      );

      return data;
    },
    {
      enabled: !!id && id !== 'empty',
      staleTime: 1000 * 60,

      onSettled: () => {
        setLastSearch(search);
      },
    },
  );

  const { data: summaryData } = useQuery(
    ['groups summary', id],
    async () => {
      const { data } = await api.get<SummaryProps>(
        `/groups/${id}/series/summary`,
      );
      return data;
    },
    {
      staleTime: ms('5 min'),
      enabled: !!id && id !== 'empty',
    },
  );

  useEffect(() => {
    openIndicatorFilters(false);
    openPremiumFilters(false);
  }, [openIndicatorFilters, openPremiumFilters]);

  const navigateToIndicator = (serie: Serie) => {
    if (serie) {
      dispatch(
        setInfoToViewTheSerie({
          id: id ?? '',
          serie: {
            code: serie.code,
            indicatorCode: serie.indicator_code,
            region: serie.region['en-us'],
            frequency: serie.aggregation['en-us'],
            primaryTransformation: serie.primary_transformation['en-us'],
            secondaryTransformation: serie.second_transformation['en-us'],
          },
          page,
        }),
      );

      navigate(`/feature-store/indicators/${serie.indicator_code}`);
    }
  };

  useEffect(() => {
    if (id) {
      setterIdFavoriteViewingPage(id);
      setPage(1);
    }
  }, [setterIdFavoriteViewingPage, id]);

  const handleSearchSerie = (value: string) => {
    setSearch(value);

    if (value.length > 30) {
      setSearchError(
        translate('preDefinedSearchMaxCharactersError').replace('XX', '30'),
      );
      return;
    }

    if (value.length > 0 && value.length < 3) {
      setSearchError(translate('searchMinCharactersError').replace('XX', '03'));
      return;
    }

    setSearchError('');

    if (value !== search) {
      value.length >= 3 && setPage(1);
    }
  };

  const checkSummarySeries = () => {
    const hasMaintenanceContent = !!summaryData?.total_maintenance;

    if (hasMaintenanceContent) {
      setSummaryModalVisible(true);
    }
  };

  const handleDownload = async (typeDownload: string) => {
    setDownloadLoading(true);
    setShowOptionsDownload(false);

    if (typeDownload === 'excel') {
      try {
        const { data, headers } = groupData?.tabs?.length
          ? await apiBFF.get(`/featurestore/groups/${id}/tabs/download/xlsx`, {
              responseType: 'blob',
            })
          : await api.get(`/groups/${id}/download/xlsx`, {
              responseType: 'blob',
            });

        if (data) {
          const fileURL = window.URL.createObjectURL(
            new Blob([data], { type: 'application/gzip' }),
          );

          const contentDisposition = (headers as IResponseHeadersDownload)[
            'content-disposition'
          ];

          const name = contentDisposition.slice(
            contentDisposition.indexOf('filename="') + 10,
            contentDisposition.length - 1,
          );

          const link = document.createElement('a');

          if (link.download !== undefined) {
            link.setAttribute('href', fileURL);
            link.setAttribute('download', name);
            link.setAttribute('data-testid', 'download-start-excel');
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
          }
        }

        checkSummarySeries();
        setDownloadLoading(false);
      } catch (err) {
        const error: AxiosError<DataError> | any = err;
        const errorMessage =
          error?.response?.data?.detail?.detail ??
          error?.response?.data?.detail?.description ??
          translate('preDefinedDownloadError');

        setFailedModalInfo({
          title: translate('requestFailed'),
          description: errorMessage,
        });
        setDownloadLoading(false);
        setFailedModalVisible(true);
      }
    }

    if (typeDownload === 'csv') {
      setDownloadLoading(true);
      setShowOptionsDownload(false);

      try {
        const { data, headers } = groupData?.tabs?.length
          ? await api.get(`/groups/${id}/tabs/download/csv`, {
              responseType: 'blob',
            })
          : await api.get(`/groups/${id}/download/csv`, {
              responseType: 'blob',
            });

        if (data) {
          const fileURL = window.URL.createObjectURL(
            new Blob([data], { type: 'application/gzip' }),
          );

          const contentDisposition = (headers as IResponseHeadersDownload)[
            'content-disposition'
          ];

          const name = contentDisposition.slice(
            contentDisposition.indexOf('filename="') + 10,
            contentDisposition.length - 1,
          );

          const link = document.createElement('a');
          if (link.download !== undefined) {
            link.setAttribute('href', fileURL);
            link.setAttribute('download', name);
            link.setAttribute('data-testid', 'download-start-csv');
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
          }

          checkSummarySeries();
          setDownloadLoading(false);
        }
      } catch (err) {
        const error: AxiosError<DataError> | any = err;
        const errorMessage =
          error?.response?.data?.detail?.detail ??
          error?.response?.data?.detail?.description ??
          translate('preDefinedDownloadError');

        setFailedModalInfo({
          title: translate('requestFailed'),
          description: errorMessage,
        });
        setDownloadLoading(false);
        setFailedModalVisible(true);
      }
    }
  };

  function calcTextWidth(text: string, fontSize = 14) {
    const FONT = `400 ${fontSize}px Inter`;

    const textWidth = getTextWidth(text, FONT);
    const padding = 36;
    const minWidth = 200 - padding;
    let maxWidth = minWidth;

    if (textWidth <= minWidth) {
      return getTextWidth(text, FONT) + padding;
    }

    while (getTextWidthWithLineBreaks(text, FONT, maxWidth).lineBreaks > 1) {
      maxWidth += 10;
    }

    const widthWithLineBreak = getTextWidthWithLineBreaks(text, FONT, maxWidth);
    const widthLineBreak = widthWithLineBreak.width + padding;

    return Math.max(minWidth, widthLineBreak);
  }

  const showMessageExportLimit = () => {
    if (
      groupSeriesData?.total !== undefined &&
      groupSeriesData?.total > QUANTITY_EXPORT
    ) {
      return true;
    }
    return false;
  };

  const messageToolTip = showMessageExportLimit()
    ? translate('exportSeriesLimitExport')
    : isFreemium && favoriteType === 'data'
    ? translate('resourcePaidMakeUpgrade')
    : '';

  return (
    <Container>
      <Head title={translate('favoritesTitle')} />
      <>
        <HeaderContainer>
          <FavoriteTypeHeader />

          <HeaderContent data-testid="header-content">
            {isLoadingGroup ? (
              <HeaderSkeletonContainer>
                <ContainerSkeleton
                  withLoading={false}
                  style={{ width: '250px', height: '34px' }}
                />
                <ContainerSkeleton
                  withLoading={false}
                  style={{ width: '87px', height: '34px' }}
                />
                <ContainerSkeleton
                  withLoading={false}
                  style={{ width: '260px', height: '34px' }}
                />
              </HeaderSkeletonContainer>
            ) : (
              !!groupData && (
                <>
                  <h4 data-testid="info-container">{groupData?.name}</h4>

                  <Tooltip
                    id="feature-store-tooltip-export"
                    className="customTooltipTheme"
                  />
                  <RoundedButtonsContainer>
                    <ContentButtonExport
                      data-tooltip-id="feature-store-tooltip-export"
                      data-tooltip-html={messageToolTip}
                    >
                      <ExportButton
                        data-testid="button-export-series"
                        data-cy="button-export-series"
                        onClick={() => {
                          setShowOptionsDownload(true);
                        }}
                        disabled={
                          groupData?.series?.length === 0 ||
                          groupData?.series?.length === undefined ||
                          groupData?.series?.length > QUANTITY_EXPORT ||
                          (isFreemium && favoriteType === 'data')
                        }
                      >
                        <DownloadSimple />
                        {translate('export')}
                      </ExportButton>
                      <MenuContainer
                        visible={showOptionsDownload}
                        style={{ top: 'calc(100% + 0.5rem)', right: '0rem' }}
                      >
                        {showOptionsDownload && (
                          <MenuOverlay
                            data-testid="menu-overlay"
                            visible={showOptionsDownload}
                            onClick={() => setShowOptionsDownload(false)}
                          />
                        )}
                        <MenuOption
                          type="button"
                          data-testid="buttonXLXS"
                          onClick={() => handleDownload('excel')}
                          position="start"
                        >
                          <FileX size="1.25rem" />
                          <p>Excel</p>
                        </MenuOption>
                        <MenuOption
                          type="button"
                          data-testid="buttonCSV"
                          onClick={() => handleDownload('csv')}
                          position="end"
                        >
                          <FileText size="1.25rem" />
                          <p>CSV</p>
                        </MenuOption>
                      </MenuContainer>
                    </ContentButtonExport>
                  </RoundedButtonsContainer>
                </>
              )
            )}
          </HeaderContent>

          <Description>
            {isLoadingGroup ? (
              <ContainerSkeleton
                withLoading={false}
                style={{ width: '500px', height: '32px' }}
              />
            ) : (
              groupData?.description
            )}
          </Description>
        </HeaderContainer>

        {isLoadingGroup && (
          <ContainerSkeleton
            withLoading={false}
            style={{ width: '150px', height: '34px', marginTop: '2rem' }}
          />
        )}

        {groupData?.series && groupData?.series.length > 0 && (
          <ButtonsContainer>
            <ButtonCopyToClipboard
              isDisabled={false}
              textToCopy={groupData?.series.toString() ?? ''}
              messageBeforeCopy={
                groupData?.series.length > 1
                  ? translate('viewFeatureCopySeriesToClipboard')
                  : translate('viewFeatureCopySerieToClipboard')
              }
              messageAfterCopy={translate('copied')}
            />

            <Input
              icon={
                <MagnifyingGlass
                  size="1.25rem"
                  style={{ marginLeft: '2px', color: light.colors.gray4 }}
                />
              }
              testid="input-search-series"
              placeholder={translate('preDefinedSearchSeries')}
              value={search}
              onChange={({ target: { value } }) => handleSearchSerie(value)}
              style={{ maxWidth: '280px' }}
              error={searchError}
            />
          </ButtonsContainer>
        )}
      </>

      {isGroupSeriesErrored || isGroupErrored ? (
        <ContainerMaintenance
          content="table"
          text={translate('preDefinedUnableGroup')}
        />
      ) : !isLoadingGroupSeries &&
        !isFetchingGroupSeries &&
        groupSeriesData?.total === 0 &&
        !search &&
        lastSearch === '' ? (
        // eslint-disable-next-line react/jsx-indent
        <FavoriteStatus
          buttonType="empty"
          onClick={() => navigate('/feature-store/indicators')}
        />
      ) : (!isLoadingGroupSeries &&
          !isFetchingGroupSeries &&
          groupSeriesData?.total === 0 &&
          !search &&
          lastSearch === '') ||
        id === 'empty' ? (
        // eslint-disable-next-line react/jsx-indent
        <FavoriteStatus
          buttonType="nonexistent"
          onClick={() => setCreateFavoriteModalIsVisible(true)}
        />
      ) : groupSeriesData?.total === 0 &&
        !isLoadingGroupSeries &&
        !isFetchingGroupSeries &&
        search ? (
        // eslint-disable-next-line react/jsx-indent
        <Content>
          <Status
            type="noSearchResults"
            title={`${translate('preDefinedAnySeries')} "${lastSearch}".`}
          />
        </Content>
      ) : (
        <TableV2.Container style={{ marginTop: 8 }}>
          <TableV2.Root data-testid="series-table">
            <TableV2.Thead>
              <TableV2.Tr>
                <TableV2.Th>{translate('preDefinedName')}</TableV2.Th>
                <TableV2.Th>{translate('preDefinedSource')}</TableV2.Th>
                <TableV2.Th>{translate('preDefinedLastUpdate')}</TableV2.Th>
                <TableV2.Th>{translate('preDefinedStartDate')}</TableV2.Th>
                <TableV2.Th>{translate('preDefinedEndDate')}</TableV2.Th>
                <TableV2.Th>{translate('preDefinedFrequency')}</TableV2.Th>
                <TableV2.Th>{translate('preDefinedRegion')}</TableV2.Th>
                <TableV2.Th>{translate('preDefinedPrimaryTr')}</TableV2.Th>
                <TableV2.Th>{translate('preDefinedSecondaryTr')}</TableV2.Th>
                <TableV2.Th hasBorderLeftLastColl />
              </TableV2.Tr>
            </TableV2.Thead>
            <TableV2.Tbody>
              {isLoadingGroupSeries ||
              isFetchingGroupSeries ||
              isLoadingGroup ||
              isLoadingDebounce ? (
                <>
                  {Array.from({
                    length: QUANTITY_ITEMS_PAGE,
                  }).map((_, index) => (
                    <TableLoading
                      key={`${index + 1}`}
                      actions={
                        (groupData?.is_predefined === true && user.isFsAdmin) ||
                        groupData?.is_predefined === false
                          ? 2
                          : 1
                      }
                    />
                  ))}
                </>
              ) : (
                groupSeriesData &&
                groupSeriesData.data.length !== 0 && (
                  <>
                    {groupSeriesData?.data?.map((serie) => (
                      <TableV2.Tr key={serie.code}>
                        <TableV2.Td
                          style={{
                            minWidth: calcTextWidth(serie.name[language]),
                          }}
                        >
                          {serie.name[language] ?? serie.name['en-us']}
                        </TableV2.Td>
                        <TableV2.Td>{serie.source}</TableV2.Td>
                        <TableV2.Td
                          style={{
                            minWidth: calcTextWidth(
                              format(
                                new Date(serie.last_updated),
                                `${translateFormat}, HH:mm`,
                              ),
                            ),
                          }}
                        >
                          {format(
                            new Date(serie.last_updated),
                            `${translateFormat}, HH:mm`,
                          )}
                        </TableV2.Td>
                        <TableV2.Td>
                          {format(new Date(serie.date_start), translateFormat)}
                        </TableV2.Td>
                        <TableV2.Td>
                          {format(new Date(serie.date_end), translateFormat)}
                        </TableV2.Td>
                        <TableV2.Td>
                          {serie.aggregation[language] ??
                            serie.aggregation['en-us']}
                        </TableV2.Td>
                        <TableV2.Td>
                          {serie.region[language] ?? serie.region['en-us']}
                        </TableV2.Td>
                        <TableV2.Td>
                          {serie.primary_transformation[language] ??
                            serie.primary_transformation['en-us']}
                        </TableV2.Td>
                        <TableV2.Td>
                          {serie.second_transformation[language] ??
                            serie.second_transformation['en-us']}
                        </TableV2.Td>
                        <TableV2.Td hasBorderLeftLastColl>
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                              gap: '1rem',
                            }}
                          >
                            <ButtonIcon
                              onClick={() => {
                                navigateToIndicator(serie);
                              }}
                            >
                              <MagnifyingGlass
                                data-testid={`button-go-to-serie-${serie.indicator_code}`}
                                size="1.25rem"
                              />
                            </ButtonIcon>
                            {((groupData?.is_predefined === true &&
                              user.isFsAdmin &&
                              groupData.category !== 'data') ||
                              groupData?.is_predefined === false) && (
                              <ButtonIcon
                                onClick={() => {
                                  setSerieToDelete(serie.code);
                                  setDeleteModalVisible(true);
                                }}
                                data-testid={`button-delete-series-${serie.code}`}
                                data-cy={`button-delete-series-${serie.code}`}
                              >
                                <Trash size="1.25rem" />
                              </ButtonIcon>
                            )}
                          </div>
                        </TableV2.Td>
                      </TableV2.Tr>
                    ))}
                    {Array.from({
                      length:
                        QUANTITY_ITEMS_PAGE - groupSeriesData?.data?.length,
                    }).map((_, index) => (
                      <TableV2.Tr
                        key={`tr-empty-${index + 1}`}
                        data-testid="tr-empty"
                        height="24px"
                      >
                        {Array.from({ length: 10 }).map((__, tdIndex) => (
                          <TableV2.Td
                            hasBorderLeftLastColl
                            key={`tr-empty-${index + 1}-td-${tdIndex + 1}`}
                          />
                        ))}
                      </TableV2.Tr>
                    ))}
                  </>
                )
              )}
            </TableV2.Tbody>
          </TableV2.Root>
          <TableV2.Footer>
            <PaginationV2
              page={page}
              setPage={setPage}
              total={groupSeriesData?.total || 0}
              quantityItemsPerPage={QUANTITY_ITEMS_PAGE}
              name={translate('preDefinedSeriesTitle').toLocaleLowerCase()}
              isLoading={isLoadingGroupSeries}
            />
          </TableV2.Footer>
        </TableV2.Container>
      )}

      {deleteModalVisible && serieToDelete && (
        <DeleteSeriesModal
          serieCode={serieToDelete}
          visible={deleteModalVisible}
          setVisible={setDeleteModalVisible}
          setError={setFailModalVisible}
        />
      )}

      <FailedModal
        visible={failedModalVisible}
        setVisible={setFailedModalVisible}
        errorInfo={failedModalInfo}
      />

      <FailedModal
        visible={failModalVisible}
        setVisible={setFailModalVisible}
        errorInfo={{
          title: translate('requestFailed'),
          description: translate('preDefinedExcludedError'),
        }}
      />

      <ModalLoading
        visible={downloadLoading}
        message="Downloading..."
        data-testid="download-start"
      />

      <WarningModal
        visible={summaryModalVisible}
        setVisible={(prevValue) => {
          setSummaryModalVisible(prevValue);
        }}
        errorInfo={{
          description: translate('exportSeriesMaintenance'),
        }}
      />

      {createFavoriteModalIsVisible && (
        <CreateGroupModal
          visible
          categoryType={favoriteType}
          setVisible={() => setCreateFavoriteModalIsVisible(false)}
          predefined={favoriteType !== 'user'}
        />
      )}
    </Container>
  );
};
