import React, { useContext, useState } from 'react';

import { Heart, Plus } from 'phosphor-react';
import { useTranslation } from 'react-i18next';
import { useInfiniteQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import MaintenanceImage from 'src/assets/maintenance.svg';
import { ContainerSkeleton } from 'src/components/ContainerSkeleton';
import { BackToHome } from 'src/feature-store/components/BackToHome';
import { FeatureStoreSidebarContext } from 'src/feature-store/Contexts/NavigationContext';
import { FavoriteType } from 'src/feature-store/Contexts/types';
import { CreateGroupModal } from 'src/feature-store/pages/Groups/components/CreateGroupModal';
import { GroupCard } from 'src/feature-store/pages/Groups/components/GroupCard';
import { MyGroupsProps } from 'src/feature-store/pages/Groups/types';
import api from 'src/feature-store/service/api';
import { RootState } from 'src/redux/store';
import { queryClient } from 'src/service/queryClient';

import {
  AddNew,
  Container,
  ContainerMaintenance,
  FavoriteOption,
  Header,
  ListFavoritesContainer,
  MaintenanceInfo,
  Slash,
  Title,
  TitleContainer,
} from './styles';

type GroupsPage = {
  pages: MyGroupsProps[];
};

export const FavoriteTypeHeader: React.FC = () => {
  const QUANTITY_FAVORITES_PAGE = 15;

  const [createFavoriteModalIsVisible, setCreateFavoriteModalIsVisible] =
    useState(false);

  const { t: translate } = useTranslation();
  const { favoriteType, setterFavoriteType } = useContext(
    FeatureStoreSidebarContext,
  );
  const { isFsAdmin } = useSelector((state: RootState) => state.auth.user);
  const navigate = useNavigate();

  const {
    data: favorites,
    isFetching: isFavoriteFetching,
    isLoading: isFavoriteLoading,
    isError: isFavoriteErrored,
    fetchNextPage: fetchNextPageFavorites,
    hasNextPage: isFavoriteHasNextPage,
    isFetchingNextPage: isFavoriteFetchingNextPage,
  } = useInfiniteQuery(
    ['favorites', favoriteType],
    async ({ pageParam = 0 }) => {
      const userUrl = `/groups?skip=${
        pageParam * QUANTITY_FAVORITES_PAGE
      }&limit=${QUANTITY_FAVORITES_PAGE}`;

      const predefinedsUrl = `/groups/predefineds?category=${favoriteType}&skip=${
        pageParam * QUANTITY_FAVORITES_PAGE
      }&limit=${QUANTITY_FAVORITES_PAGE}`;

      const url = favoriteType === 'user' ? userUrl : predefinedsUrl;

      const { data } = await api.get<MyGroupsProps>(url);

      if (data) {
        const firstFavoriteId = data.data[0]?.id;

        firstFavoriteId &&
          navigate(`/feature-store/favorites/${firstFavoriteId}`);
      }

      return data;
    },
    {
      staleTime: 1000 * 60,
      getNextPageParam: (lastPage, pages) => {
        if (pages.length * QUANTITY_FAVORITES_PAGE < lastPage.total) {
          return pages.length;
        }
        return undefined;
      },
    },
  );

  const handleOnScroll = (event: React.UIEvent<HTMLDivElement>): void => {
    const isEnd =
      Math.ceil(
        event.currentTarget.scrollWidth - event.currentTarget.scrollLeft,
      ) === event.currentTarget.clientWidth;

    if (
      isEnd &&
      !isFavoriteLoading &&
      !isFavoriteFetching &&
      !isFavoriteErrored &&
      isFavoriteHasNextPage
    ) {
      fetchNextPageFavorites();
    }
  };

  const array = Array.from(Array(QUANTITY_FAVORITES_PAGE), (_, i) => i);
  const favoritesListIsLoading = array.map((i) => (
    <ContainerSkeleton
      key={i + 1}
      withLoading={false}
      style={{
        width: `${Math.random() * (270 - 120) + 120}px`,
        height: '4.875rem',
      }}
    />
  ));

  const showToFavoriteTypeUser = !(
    favoriteType === 'user' && favorites?.pages[0].total === 0
  );
  const favoriteTypeAdminCanCreateGroup = isFsAdmin && favoriteType !== 'data';
  const favoriteTypeUserCanCreateGroup = !isFsAdmin && favoriteType === 'user';
  const canCreateGroup =
    favoriteTypeAdminCanCreateGroup || favoriteTypeUserCanCreateGroup;

  const handleFirstFavoriteSeries = (type: FavoriteType) => {
    const cacheFavorites = queryClient.getQueryData<GroupsPage>([
      'favorites',
      type,
    ]);
    const firstFavoriteId = cacheFavorites?.pages[0].data[0]?.id;
    firstFavoriteId && navigate(`/feature-store/favorites/${firstFavoriteId}`);

    type === 'user' &&
      !firstFavoriteId &&
      navigate(`/feature-store/favorites/empty`);
  };

  return (
    <Container>
      <BackToHome />
      <Header>
        <FavoriteOption
          isActive={favoriteType === 'user'}
          onClick={() => {
            setterFavoriteType('user');
            handleFirstFavoriteSeries('user');
          }}
          data-testid="favorite-type-user"
        >
          {translate('homeFeatureStoreMyFavorites')}
        </FavoriteOption>
        <Slash>/</Slash>
        <FavoriteOption
          isActive={favoriteType === 'highlights'}
          onClick={() => {
            setterFavoriteType('highlights');
            handleFirstFavoriteSeries('highlights');
          }}
          data-testid="favorite-type-highlights"
        >
          {translate('homeFeatureStoreHighlights')}
        </FavoriteOption>
        <Slash>/</Slash>
        <FavoriteOption
          isActive={favoriteType === 'data'}
          onClick={() => {
            setterFavoriteType('data');
            handleFirstFavoriteSeries('data');
          }}
          data-testid="favorite-type-data"
        >
          {translate('groupViewHeaderMacroIndicator')}
        </FavoriteOption>
        <Slash>/</Slash>
        <FavoriteOption
          isActive={favoriteType === 'model'}
          onClick={() => {
            setterFavoriteType('model');
            handleFirstFavoriteSeries('model');
          }}
          data-testid="favorite-type-model"
        >
          {translate('groupViewHeaderModelSeries')}
        </FavoriteOption>
      </Header>

      <TitleContainer>
        <Title>
          <Heart />
          <span>{translate('favoritesTitle')}</span>
        </Title>

        {canCreateGroup && showToFavoriteTypeUser && (
          <AddNew
            onClick={() => setCreateFavoriteModalIsVisible(true)}
            data-testid="button-create-new-favorite"
          >
            <Plus />
            {translate('groupViewAddNew')}
          </AddNew>
        )}
      </TitleContainer>

      <ListFavoritesContainer
        onScroll={handleOnScroll}
        data-testid="list-favorites-container"
      >
        {isFavoriteLoading
          ? favoritesListIsLoading
          : favorites?.pages.map((favorite, index) => (
              // eslint-disable-next-line react/jsx-indent, react/no-array-index-key
              <React.Fragment key={index + 1}>
                {favorite.data.map((fav) => (
                  <GroupCard
                    key={fav.id}
                    id={fav.id}
                    name={fav.name}
                    iconUrl={fav.icon_url}
                    description={fav.description}
                    isLoading={isFavoriteFetchingNextPage}
                    predefined={fav.is_predefined}
                  />
                ))}
              </React.Fragment>
            ))}

        {isFavoriteErrored && (
          <ContainerMaintenance data-testid="container-maintenance">
            <img src={MaintenanceImage} alt="Maintenance" />

            <MaintenanceInfo>
              <h5>{translate('indicatorsForAnalyzeErrorTitle')}</h5>
              <p>{translate('indicatorsForAnalyzeErrorDescription')}</p>
            </MaintenanceInfo>
          </ContainerMaintenance>
        )}
      </ListFavoritesContainer>

      {createFavoriteModalIsVisible && (
        <CreateGroupModal
          visible
          categoryType={favoriteType}
          setVisible={() => setCreateFavoriteModalIsVisible(false)}
          predefined={favoriteType !== 'user'}
        />
      )}
    </Container>
  );
};
