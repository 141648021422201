import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { Frequency } from '../Project';

export type WorkspaceY = {
  project_id: string;
  y: string;
  y_label: string;
  model_id: string;
  is_inflated: boolean;
};

export type WorkspaceRelease = {
  id: string;
  name: string;
  status: string;
  preview: boolean;
  performances: {
    id: string;
    project_id: string;
    y_label: string;
    type: string;
  }[];
  published_at: Date | null;
  index?: number | null;
};

export type WorkspaceFilters = {
  [key: string]: {
    name: string;
    options: string[];
  } | null;
};

export type WorkspaceYFilters = {
  [key: string]: {
    [key: string]: string | null;
  };
};

export type WorkspaceReleaseSelected =
  | WorkspaceRelease & {
      data: {
        ys: WorkspaceY[];
        filters: WorkspaceFilters;
        y_filters: WorkspaceYFilters;
        aggregation: {
          enable: boolean;
        } | null;
        approval_flow: {
          enable: boolean;
        } | null;
      };
    };

export type WorkspaceColumn =
  | 'Model'
  | 'MASE'
  | 'MAPE'
  | 'MASEs'
  | 'WMAPE'
  | 'Transformation'
  | 'RMSE'
  | 'MPE';

export type PayloadWorkspace = {
  id: string | null;
  name: string | null;
  icon: string | null;
  releaseCurrent: string | null;
  releasePreview: string | null;
  releaseSelected?: WorkspaceReleaseSelected | null;
  columns?: WorkspaceColumn[];
  description: string | null;
  lastUpdated: string | null;
  userRole: 'editor' | 'reader' | 'manager' | null;
  createdBy: string | null;
  frequency: Frequency | 'yearly' | null;
  ySelected?: WorkspaceY | null;
  status?: string;
  lockedFor?: string;
  lockExpireAt?: string;
  canSyncAdjust: boolean;
};

const initialState: PayloadWorkspace = {
  id: null,
  name: null,
  icon: null,
  releaseCurrent: null,
  releasePreview: null,
  releaseSelected: null,
  columns: ['Model', 'MASE', 'MAPE', 'MASEs', 'WMAPE', 'RMSE', 'MPE'],
  description: null,
  lastUpdated: null,
  userRole: null,
  createdBy: null,
  frequency: null,
  ySelected: null,
  canSyncAdjust: true,
};

export const WorkspaceState = createSlice({
  name: 'workspace',
  initialState,
  reducers: {
    insert: (state, action: PayloadAction<PayloadWorkspace>) => {
      state.id = action.payload.id;
      state.name = action.payload.name;
      state.icon = action.payload.icon;
      state.releaseCurrent = action.payload.releaseCurrent;
      state.releasePreview = action.payload.releasePreview;
      state.description = action.payload.description;
      state.lastUpdated = action.payload.lastUpdated;
      state.userRole = action.payload.userRole;
      state.createdBy = action.payload.createdBy;
      state.status = action.payload.status;
      state.frequency = action.payload.frequency;
      state.canSyncAdjust = true;
    },
    updateReleaseSelected: (
      state,
      action: PayloadAction<{
        releaseSelected: WorkspaceReleaseSelected | null;
        ySelected: WorkspaceY | null;
      }>,
    ) => {
      state.releaseSelected = action.payload.releaseSelected;
      state.ySelected = action.payload.ySelected;
      state.canSyncAdjust = true;
    },
    updateYSelected: (state, action: PayloadAction<WorkspaceY>) => {
      state.ySelected = action.payload;
      state.canSyncAdjust = true;
    },
    clear: (state) => {
      state.id = null;
      state.name = null;
      state.icon = null;
      state.releaseCurrent = null;
      state.releaseSelected = null;
      state.columns = [];
      state.description = null;
      state.lastUpdated = null;
      state.userRole = null;
      state.createdBy = null;
      state.frequency = null;
      state.ySelected = null;
      state.canSyncAdjust = true;
    },
    updateStatus: (state, action: PayloadAction<string>) => {
      state.status = action.payload;
    },
    updateCanSyncAdjust: (state, action: PayloadAction<boolean>) => {
      state.canSyncAdjust = action.payload;
    },
  },
});

export const {
  insert,
  clear,
  updateReleaseSelected,
  updateStatus,
  updateYSelected,
  updateCanSyncAdjust,
} = WorkspaceState.actions;

export default WorkspaceState.reducer;
