import React, { ReactElement, createContext, useState } from 'react';

import {
  FavoriteType,
  FeatureStoreSidebarContextProps,
  IndicatorFilterProps,
} from './types';

export const FeatureStoreSidebarContext = createContext(
  {} as FeatureStoreSidebarContextProps,
);

export const FeatureStoreSidebarProvider: React.FC<{
  children: ReactElement;
}> = ({ children }) => {
  const [indicatorFiltersVisible, setIndicatorFiltersVisible] = useState(false);
  const [premiumFiltersVisible, setPremiumFiltersVisible] = useState(false);
  const [idFavoriteViewingPage, setIdFavoriteViewingPage] =
    useState<string>('');
  const [idUserFavoriteViewingHome, setIdUserFavoriteViewingHome] =
    useState<string>('');
  const [idHighlightsFavoriteViewingHome, setIdHighthsFavoriteViewingHome] =
    useState<string>('');
  const [favoriteType, setFavoriteType] = useState<FavoriteType>('user');
  const [favoriteTypeHome, setFavoriteTypeHome] =
    useState<FavoriteType>('user');
  const [indicatorFilterType, setIndicatorFilterType] =
    useState<IndicatorFilterProps>(null);

  function openIndicatorFilters(value: boolean) {
    setIndicatorFiltersVisible(value);
  }

  function openPremiumFilters(value: boolean) {
    setPremiumFiltersVisible(value);
  }

  function setterIdFavoriteViewingPage(id: string) {
    setIdFavoriteViewingPage(id);
  }

  function setterIdUserFavoriteViewingHome(id: string) {
    setIdUserFavoriteViewingHome(id);
  }

  function setterIdHighlightsFavoriteViewingHome(id: string) {
    setIdHighthsFavoriteViewingHome(id);
  }

  function setterFavoriteType(favoriteTypeValue: FavoriteType) {
    setFavoriteType(favoriteTypeValue);
  }

  function setterFavoriteTypeHome(favoriteTypeHomeValue: FavoriteType) {
    setFavoriteTypeHome(favoriteTypeHomeValue);
  }

  function setterIndicatorFilterType(indicatorType: IndicatorFilterProps) {
    setIndicatorFilterType(indicatorType);
  }

  return (
    <FeatureStoreSidebarContext.Provider
      value={{
        openIndicatorFilters,
        indicatorFiltersVisible,
        openPremiumFilters,
        premiumFiltersVisible,
        setterIdFavoriteViewingPage,
        idFavoriteViewingPage,
        setterIdUserFavoriteViewingHome,
        idHighlightsFavoriteViewingHome,
        setterIdHighlightsFavoriteViewingHome,
        idUserFavoriteViewingHome,
        setterFavoriteType,
        favoriteType,
        setterFavoriteTypeHome,
        favoriteTypeHome,
        setterIndicatorFilterType,
        indicatorFilterType,
      }}
    >
      {children}
    </FeatureStoreSidebarContext.Provider>
  );
};
