import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';

import { AxiosError } from 'axios';
import { format, sub } from 'date-fns';
import ms from 'ms';
import { Calendar, DownloadSimple, FileText, FileX } from 'phosphor-react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
import { ButtonCopyToClipboard } from 'src/components/ButtonCopyToClipboard';
import { ContainerMaintenance } from 'src/components/ContainerMaintenance';
import { ContainerSkeleton } from 'src/components/ContainerSkeleton';
import { MenuContainer, MenuOption } from 'src/components/Menu/styles';
import { FailedModal } from 'src/components/Modal/Failed';
import { ModalLoading } from 'src/components/Modal/Loading';
import { WarningModal } from 'src/components/Modal/Warning';
import { DateRange, RangeDatePicker } from 'src/components/RangeDatePicker';
import { TableV2 } from 'src/components/TableV2';
import { BackToHome } from 'src/feature-store/components/BackToHome';
import { FeatureStoreSidebarContext } from 'src/feature-store/Contexts/NavigationContext';
import { setMyFavoriteDateRange } from 'src/feature-store/redux/reducers/Groups';
import api from 'src/feature-store/service/api';
import { DataError } from 'src/interface/axios';
import { RootState } from 'src/redux/store';
import apiBFF from 'src/service/api';
import { getDateMonthYear } from 'src/utils/dates/dateFormatHandler';
import { getTextWidth } from 'src/utils/getTextWidth';
import { getTextWidthWithLineBreaks } from 'src/utils/getTextWidthWithLineBreaks';
import { Head } from 'src/components/Head';

import { SubscribeAlert } from '../../../components/SubscribeToAlert';
import { Group } from '../../Groups/types';
import { ListIndicatorsForAnalyze } from '../../HomeFeatureStore/ListIndicatorsForAnalyze';
import { Tabs } from '../components/Tabs';
import { IResponseHeadersDownload, SummaryProps } from '../types';
import {
  ButtonsContainer,
  ButtonsContainerSkeleton,
  Container,
  ContainerTable,
  ContainerTabs,
  ContentButtonExport,
  ContentPeriod,
  DatePickerCopyCodes,
  Description,
  ExportButton,
  HeaderContainer,
  HeaderContent,
  InfoContainer,
  MenuOverlay,
  RoundedButtonsContainer,
  ThColumn,
} from './styles';
import { DataTableProps, SerieInfoProps } from './types';

type ParamsProps = {
  id: string;
  tab: string;
};

export type ErrorObject = {
  title?: string;
  description?: string;
  cloudIcon?: boolean;
};

export const DataTable: React.FC = () => {
  const QUANTITY_ITEMS_COLUMNS_AS_NAME = 8;
  const QUANTITY_EXPORT = 150;

  const { id, tab: idTab } = useParams<ParamsProps>();
  const { pathname } = useLocation();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { t: translate } = useTranslation();

  const [failModalVisible, setFailModalVisible] = useState(false);
  const [failedModalInfo, setFailedModalInfo] = useState<ErrorObject>();
  const [failedModalVisible, setFailedModalVisible] = useState(false);
  const [showOptionsDownload, setShowOptionsDownload] =
    useState<boolean>(false);
  const [downloadLoading, setDownloadLoading] = useState<boolean>(false);
  const [summaryModalVisible, setSummaryModalVisible] = useState(false);
  const [dateRangeAux, setDateRangeAux] = useState<DateRange>([null, null]);
  const [dateRangeFilter, setDateRangeFilter] = useState<[Date, Date] | null>(
    null,
  );
  const [serieCode, setSerieCode] = useState<null | string>(null);
  const [firstThWidth, setFirstThWidth] = useState(0);
  const [firstThHeight, setFirstThHeight] = useState(0);

  const { language } = useSelector((state: RootState) => state.auth.user);
  const { user } = useSelector((state: RootState) => state.auth);
  const { dateRange, idSelected } = useSelector(
    (state: RootState) => state.groupNavigation.myFavorites,
  );

  const containerTableRef = useRef<HTMLDivElement | null>(null);
  const firstThRef = useRef<HTMLTableHeaderCellElement | null>(null);

  const {
    setterIdFavoriteViewingPage,
    openIndicatorFilters,
    openPremiumFilters,
  } = useContext(FeatureStoreSidebarContext);

  const {
    data: dataGroups,
    isLoading: isLoadingGroups,
    isError: isGroupsErrored,
  } = useQuery(
    ['data group', id],
    async () => {
      const { data } = await api.get<Group>(`/groups/${id}`);

      return data;
    },
    {
      enabled: !!id,
      staleTime: 5000 * 60,
    },
  );

  const {
    data: dataTableQuery,
    isLoading: isDataTableLoading,
    isError: isDataTableErrored,
  } = useQuery<DataTableProps>(
    ['data table', id, idTab, dateRange, language],
    async () => {
      const startDate = dateRange[0]! && format(dateRange[0], 'yyyy-MM-dd');
      const endDate = dateRange[1]! && format(dateRange[1], 'yyyy-MM-dd');

      if (idTab !== 'summary') {
        const { data } = await api.get<DataTableProps>(
          `/groups/${id}/tabs/${idTab}?start_date=${startDate}&end_date=${endDate}`,
        );

        return data;
      }

      const responses = await Promise.all(
        dataGroups!.summaries.map((_, index) =>
          api.get<DataTableProps>(`/groups/${id}/summaries/${index}`),
        ),
      );

      const data: DataTableProps = {
        columns: responses.flatMap((response) => response.data.columns),
        rows: responses[0].data.rows.map((row, index) => ({
          stub: row.stub,
          records: [
            ...row.records,
            ...responses
              .slice(1)
              .flatMap((response) => response.data.rows[index].records),
          ],
        })),
        units: responses.flatMap((response) => response.data.units),
      };

      return data;
    },
    {
      enabled:
        !!id && !!idTab && !!dateRange[0] && !!dateRange[1] && !!dataGroups,
      staleTime: 5000 * 60,
    },
  );

  const { data: summaryData } = useQuery(
    ['groups summary', id],
    async () => {
      const { data } = await api.get<SummaryProps>(
        `/groups/${id}/series/summary`,
      );
      return data;
    },
    {
      staleTime: ms('5 min'),
      enabled: !!id,
    },
  );

  const { isLoading: serieInfoLoading, data: dataSerieInfo } = useQuery(
    ['serie info', serieCode],
    async () => {
      const { data } = await api.get<SerieInfoProps>(`/series/${serieCode}`);
      return data;
    },
    {
      staleTime: ms('1s'),
      enabled: !!serieCode,
    },
  );

  useEffect(() => {
    openIndicatorFilters(false);
    openPremiumFilters(false);
  }, [openIndicatorFilters, openPremiumFilters]);

  useEffect(() => {
    if (dataGroups && dataGroups.tabs.length === 0) {
      navigate(`/feature-store/favorites/${id}`);
    }
  }, [dataGroups, id, navigate]);

  useEffect(() => {
    if (containerTableRef.current) {
      containerTableRef.current.scrollLeft = 0;
      containerTableRef.current.scrollTop = 0;
    }
  }, [pathname]);

  useEffect(() => {
    if (dataSerieInfo && id) {
      if (idSelected !== id) {
        dispatch(
          setMyFavoriteDateRange({
            idSelected: id,
            dateRange: [
              sub(new Date(dataSerieInfo.data?.date_end), { months: 11 }),
              new Date(dataSerieInfo.data?.date_end),
            ],
          }),
        );
      }

      setDateRangeFilter([
        new Date(dataSerieInfo.data?.date_start),
        new Date(dataSerieInfo.data.date_end),
      ]);

      setDateRangeAux(dateRange);
    }
  }, [dataSerieInfo, dateRange, dispatch, id, idSelected]);

  useEffect(() => {
    if (id) {
      setterIdFavoriteViewingPage(id);
    }
  }, [setterIdFavoriteViewingPage, id]);

  useEffect(() => {
    if (dataGroups && dataGroups.tabs?.length > 0) {
      if (idTab !== 'summary') {
        setSerieCode(dataGroups.tabs[Number(idTab)].series[0]);
      } else {
        setSerieCode(dataGroups.summaries[0].series[0].series_code);
      }
    }
  }, [dataGroups, idTab]);

  useEffect(() => {
    if (id && dateRangeAux[0] && dateRangeAux[1]) {
      dispatch(
        setMyFavoriteDateRange({
          dateRange: dateRangeAux,
          idSelected: id,
        }),
      );
    }
  }, [dateRangeAux, dispatch, id]);

  const checkSummarySeries = () => {
    const hasMaintenanceContent = !!summaryData?.total_maintenance;

    if (hasMaintenanceContent) {
      setSummaryModalVisible(true);
    }
  };

  const handleDownload = async (typeDownload: string) => {
    setDownloadLoading(true);
    setShowOptionsDownload(false);

    if (typeDownload === 'excel') {
      try {
        const { data, headers } = await apiBFF.get(
          `/featurestore/groups/${id}/tabs/download/xlsx`,
          {
            responseType: 'blob',
          },
        );

        if (data) {
          const fileURL = window.URL.createObjectURL(
            new Blob([data], { type: 'application/gzip' }),
          );

          const contentDisposition = (headers as IResponseHeadersDownload)[
            'content-disposition'
          ];

          const name = contentDisposition.slice(
            contentDisposition.indexOf('filename="') + 10,
            contentDisposition.length - 1,
          );

          const link = document.createElement('a');

          if (link.download !== undefined) {
            link.setAttribute('href', fileURL);
            link.setAttribute('download', name);
            link.setAttribute('data-testid', 'download-start-excel');
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
          }
        }

        checkSummarySeries();
        setDownloadLoading(false);
      } catch (err) {
        const error: AxiosError<DataError> | any = err;
        const errorMessage =
          error?.response?.data?.detail?.detail ??
          error?.response?.data?.detail?.description ??
          translate('preDefinedDownloadError');

        setFailedModalInfo({
          title: translate('requestFailed'),
          description: errorMessage,
        });
        setDownloadLoading(false);
        setFailedModalVisible(true);
      }
    }

    if (typeDownload === 'csv') {
      setDownloadLoading(true);
      setShowOptionsDownload(false);

      try {
        const { data, headers } = await api.get(
          `/groups/${id}/tabs/download/csv`,
          {
            responseType: 'blob',
          },
        );

        if (data) {
          const fileURL = window.URL.createObjectURL(
            new Blob([data], { type: 'application/gzip' }),
          );

          const contentDisposition = (headers as IResponseHeadersDownload)[
            'content-disposition'
          ];

          const name = contentDisposition.slice(
            contentDisposition.indexOf('filename="') + 10,
            contentDisposition.length - 1,
          );

          const link = document.createElement('a');
          if (link.download !== undefined) {
            link.setAttribute('href', fileURL);
            link.setAttribute('download', name);
            link.setAttribute('data-testid', 'download-start-csv');
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
          }

          checkSummarySeries();
          setDownloadLoading(false);
        }
      } catch (err) {
        const error: AxiosError<DataError> | any = err;
        const errorMessage =
          error?.response?.data?.detail?.detail ??
          error?.response?.data?.detail?.description ??
          translate('preDefinedDownloadError');

        setFailedModalInfo({
          title: translate('requestFailed'),
          description: errorMessage,
        });
        setDownloadLoading(false);
        setFailedModalVisible(true);
      }
    }
  };

  const showMessageExportLimit = () => {
    if (
      summaryData?.total_accessible !== undefined &&
      summaryData?.total_accessible > QUANTITY_EXPORT
    ) {
      return true;
    }
    return false;
  };

  function handleRange(value: DateRange) {
    if (!id) {
      return;
    }

    setDateRangeAux(value);
  }

  function calcColumnWidth(column: string, fontSize = 18) {
    const FONT = `400 ${fontSize}px Inter`;

    const textWidth = getTextWidth(column, FONT);
    const padding = 36;

    const minWidth = 200 - padding;

    let maxWidth = minWidth;

    if (textWidth <= minWidth) {
      return getTextWidth(column, FONT) + padding;
    }

    while (getTextWidthWithLineBreaks(column, FONT, maxWidth).lineBreaks > 3) {
      maxWidth += 10;
    }

    const widthWithLineBreak = getTextWidthWithLineBreaks(
      column,
      FONT,
      maxWidth,
    );

    const widthLineBreak = widthWithLineBreak.width + padding;

    return Math.max(minWidth, widthLineBreak);
  }

  const isSummarySelected = idTab === 'summary';

  const columnsAsDate =
    isSummarySelected || dataGroups?.tabs[Number(idTab)].columns_as === 'date';

  const showUnitsOnHeader = !isSummarySelected && !columnsAsDate;

  const isLoading = isLoadingGroups || isDataTableLoading;

  const dataTableToShow = useMemo(
    () =>
      dataTableQuery && dataGroups && !isLoading
        ? {
            colGroup: isSummarySelected
              ? dataGroups!.summaries.map((summary) => ({
                  size: summary.dates.length,
                  name:
                    summary.title?.[user.language] || summary.title['en-us'],
                }))
              : null,
            columns: columnsAsDate
              ? dataTableQuery.columns.map((column) =>
                  getDateMonthYear(column, user.language),
                )
              : dataTableQuery.columns,
            rows: dataTableQuery.rows.map((row) => ({
              stub: columnsAsDate
                ? row.stub
                : getDateMonthYear(row.stub, user.language),
              records: row.records,
            })),
            units: dataTableQuery.units,
            levels: dataGroups.summaries.flatMap((summary) =>
              summary.series.flatMap((serie) => ({ level: serie.level })),
            ),
            boldList: dataGroups.summaries.flatMap((summary) =>
              summary.series.flatMap((serie) => ({ isBold: serie.bold })),
            ),
          }
        : null,
    [
      columnsAsDate,
      dataGroups,
      dataTableQuery,
      isLoading,
      isSummarySelected,
      user.language,
    ],
  );

  function handleFirstThSize() {
    if (firstThRef.current) {
      setFirstThWidth(Math.floor(firstThRef.current.clientWidth));
      setFirstThHeight(Math.floor(firstThRef.current.clientHeight));
    }
  }

  useEffect(() => {
    window.addEventListener('resize', handleFirstThSize);

    return () => {
      window.removeEventListener('resize', handleFirstThSize);
    };
  }, []);

  useEffect(() => {
    handleFirstThSize();
  }, [dataTableToShow]);

  useEffect(() => {
    user.isFreemium && navigate('/feature-store/home');
  }, [navigate, user.isFreemium]);

  const formatNumber = (number: number): string =>
    Intl.NumberFormat(language === 'pt-br' ? 'pt-BR' : 'en-US', {
      notation: groupInTheList ? undefined : 'compact',
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    }).format(number);

  const groupsToSkipCompactNotation = [
    'IGP-M - Índice Geral de Preços',
    'Fiscal - Resultados do Gov. Central',
    'Pnad - Pesquisa Nacional a Domicílio',
    'Caged - Emprego Formal',
    'Setor Externo - Balanço de Pagamentos',
  ];

  const groupInTheList = groupsToSkipCompactNotation.some(
    (groupName) => dataGroups?.name === groupName,
  );

  const tdValue = (tdVal: number) => formatNumber(tdVal);

  const canSeeButtonSubscribe =
    user.email?.includes('@4intelligence.ai') ||
    user.email?.includes('@4intelligence.com.br') ||
    user.email?.includes('@4casthub.com.br');

  const serieCanSeeButtonSubscribe = ![
    'US - Prices',
    'BR - Prices',
    'BR - Credit',
    'BR - Controls',
    'BR - Leading Indicators',
    'BR - Economic Activity',
  ].includes(dataGroups?.name ?? '');

  const isAlignLeft =
    dataTableToShow?.levels.some(({ level }) => level !== 0) &&
    isSummarySelected;

  const seriesCodeTabSummary = dataGroups?.summaries
    .flatMap((summary) => summary.series.flatMap((serie) => serie.series_code))
    .toString();

  const seriesCodeTab = dataGroups?.tabs[Number(idTab)]?.series.toString();

  return (
    <Container>
      <Head title={translate('indicatorsForAnalyzeHeaderTitle')} />

      {(isLoadingGroups ||
        serieInfoLoading ||
        dateRange[0] === null ||
        !dataGroups) &&
      !isGroupsErrored &&
      !isDataTableErrored ? (
        <>
          <HeaderContainer
            data-testid="header-container"
            style={{ marginTop: '0px' }}
          >
            <BackToHome />

            <HeaderContent data-testid="header-content">
              <RoundedButtonsContainer>
                <ContainerSkeleton
                  withLoading={false}
                  style={{
                    width: `${Math.random() * (400 - 300) + 300}px`,
                    height: '25px',
                  }}
                />

                <ContainerSkeleton
                  withLoading={false}
                  style={{
                    width: `38px`,
                    height: '25px',
                  }}
                />
              </RoundedButtonsContainer>

              <ContainerSkeleton
                withLoading={false}
                style={{
                  width: `100px`,
                  height: '25px',
                }}
              />
            </HeaderContent>
          </HeaderContainer>

          <ButtonsContainer>
            <ContainerSkeleton
              withLoading={false}
              style={{
                width: '100%',
                height: '42px',
              }}
            />
          </ButtonsContainer>

          <ButtonsContainerSkeleton>
            <ContainerSkeleton
              withLoading={false}
              style={{
                width: '190px',
                height: '25px',
              }}
            />
            <ContainerSkeleton
              withLoading={false}
              style={{
                width: '250px',
                height: '25px',
              }}
            />
          </ButtonsContainerSkeleton>
        </>
      ) : (
        <>
          {dataGroups?.series && (
            <HeaderContainer data-testid="header-container">
              <BackToHome />

              <HeaderContent data-testid="header-content">
                <InfoContainer data-testid="info-container">
                  {dataGroups?.name}
                </InfoContainer>

                <Tooltip
                  id="feature-store-tooltip-export"
                  className="customTooltipTheme"
                />

                <RoundedButtonsContainer>
                  {!user.isFreemium &&
                    canSeeButtonSubscribe &&
                    serieCanSeeButtonSubscribe && (
                      <SubscribeAlert
                        id={id}
                        email={user.email}
                        text={translate('dataTableFollow')}
                      />
                    )}

                  <ContentButtonExport
                    data-testid="tooltip-export-limit"
                    data-tooltip-id="feature-store-tooltip-export"
                    data-tooltip-html={
                      showMessageExportLimit()
                        ? translate('exportSeriesLimitExport')
                        : ''
                    }
                  >
                    <ExportButton
                      data-testid="button-export-series"
                      data-cy="button-export-series"
                      onClick={() => {
                        setShowOptionsDownload(true);
                      }}
                      disabled={
                        dataGroups?.series?.length === 0 ||
                        dataGroups?.series?.length === undefined ||
                        dataGroups?.series?.length >= QUANTITY_EXPORT
                      }
                    >
                      <DownloadSimple />
                      {translate('export')}
                    </ExportButton>
                  </ContentButtonExport>

                  <MenuContainer
                    visible={showOptionsDownload}
                    style={{ marginTop: '2.5rem', marginLeft: '2.5rem' }}
                  >
                    {showOptionsDownload && (
                      <MenuOverlay
                        data-testid="menu-overlay"
                        visible={showOptionsDownload}
                        onClick={() => setShowOptionsDownload(false)}
                      />
                    )}
                    <MenuOption
                      type="button"
                      data-testid="buttonXLXS"
                      onClick={() => handleDownload('excel')}
                      position="start"
                    >
                      <FileX size="1.25rem" />
                      <p>Excel</p>
                    </MenuOption>
                    <MenuOption
                      type="button"
                      data-testid="buttonCSV"
                      onClick={() => handleDownload('csv')}
                      position="end"
                    >
                      <FileText size="1.25rem" />
                      <p>CSV</p>
                    </MenuOption>
                  </MenuContainer>
                </RoundedButtonsContainer>
              </HeaderContent>

              <Description>{dataGroups?.description}</Description>
            </HeaderContainer>
          )}

          {dataGroups?.tabs && (
            <ButtonsContainer>
              <DatePickerCopyCodes>
                {dataGroups?.series &&
                  dataGroups?.series.length > 0 &&
                  dataGroups.tabs && (
                    <ButtonCopyToClipboard
                      isDisabled={isGroupsErrored}
                      textToCopy={
                        idTab === 'summary'
                          ? seriesCodeTabSummary ?? ''
                          : seriesCodeTab ?? ''
                      }
                      messageBeforeCopy={
                        dataGroups?.series.length > 1
                          ? translate('viewFeatureCopySeriesToClipboard')
                          : translate('viewFeatureCopySerieToClipboard')
                      }
                      messageAfterCopy={translate('copied')}
                    />
                  )}

                <RangeDatePicker
                  dateRange={isSummarySelected ? [null, null] : dateRangeAux}
                  onChange={handleRange}
                  frequency="monthly"
                  inputTestId="data-table-range-date-picker"
                  rangeFilter={dateRangeFilter || undefined}
                  isDisabled={isSummarySelected}
                />
              </DatePickerCopyCodes>
            </ButtonsContainer>
          )}
        </>
      )}

      {idTab && !isLoading && dataTableToShow && (
        <ContainerTable
          noBorderRadiusBottom
          isColAsDate={showUnitsOnHeader}
          firstThWidth={
            firstThWidth || Math.floor(firstThRef.current?.clientWidth || 0)
          }
          firstThHeight={
            firstThHeight || Math.floor(firstThRef.current?.clientHeight || 0)
          }
          ref={containerTableRef}
        >
          <TableV2.Root data-testid="data-table-column-as-name">
            <TableV2.Thead>
              {/* Summary */}
              {dataTableToShow.colGroup && (
                <TableV2.Tr>
                  <TableV2.Th rowSpan={2} ref={firstThRef}>
                    {translate('serie')}
                  </TableV2.Th>
                  {columnsAsDate && (
                    <TableV2.Th rowSpan={2}>
                      {translate('indicatorSeriesUnit')}
                    </TableV2.Th>
                  )}
                  {dataTableToShow.colGroup.map((col) => (
                    <TableV2.Th
                      key={col.name}
                      colSpan={col.size}
                      className="ThSummary"
                    >
                      {col.name}
                    </TableV2.Th>
                  ))}
                </TableV2.Tr>
              )}
              <TableV2.Tr>
                {!dataTableToShow.colGroup ? (
                  <>
                    <TableV2.Th ref={firstThRef}>
                      {columnsAsDate ? (
                        translate('serie')
                      ) : (
                        <ContentPeriod>
                          <Calendar size="16px" />{' '}
                          {translate('dataTablePeriod')}
                        </ContentPeriod>
                      )}
                    </TableV2.Th>
                    {columnsAsDate && (
                      <TableV2.Th>
                        {translate('indicatorSeriesUnit')}
                      </TableV2.Th>
                    )}
                  </>
                ) : null}

                {dataTableToShow?.columns.map((column, index) => (
                  <ThColumn
                    key={`column-${index + 1}`}
                    width={calcColumnWidth(column)}
                    isSummary={isSummarySelected}
                  >
                    <span>{column}</span>
                  </ThColumn>
                ))}
              </TableV2.Tr>

              {showUnitsOnHeader && (
                <TableV2.Tr>
                  <ThColumn
                    isSummary={isSummarySelected}
                    width={calcColumnWidth(translate('dataTablePeriod'))}
                  />
                  {dataTableToShow?.units.map((unit, index) => (
                    <ThColumn
                      key={`column-${index + 1}`}
                      width={calcColumnWidth(unit)}
                      isSummary={isSummarySelected}
                    >
                      <p> {unit ?? ''}</p>
                    </ThColumn>
                  ))}
                </TableV2.Tr>
              )}
            </TableV2.Thead>

            <TableV2.Tbody>
              {dataTableToShow?.rows.map((row, index) => (
                <TableV2.Tr key={`tr-${idTab}-${row.stub}`}>
                  <TableV2.Td
                    alignLeft={isAlignLeft}
                    style={{
                      minWidth: columnsAsDate
                        ? `${calcColumnWidth(row.stub, 14)}px`
                        : 'unset',
                      maxWidth: columnsAsDate
                        ? `${calcColumnWidth(row.stub, 14)}px`
                        : 'unset',
                      paddingLeft: isSummarySelected
                        ? `${(dataTableToShow.levels[index].level + 1) * 16}px`
                        : 0,
                      fontWeight:
                        dataTableToShow?.boldList[index]?.isBold &&
                        isSummarySelected
                          ? '700'
                          : '500',
                    }}
                  >
                    {row.stub}
                  </TableV2.Td>

                  {columnsAsDate && (
                    <TableV2.Td>{dataTableToShow.units[index]}</TableV2.Td>
                  )}

                  {row.records.map((td, indexTd) => (
                    <TableV2.Td
                      key={`td-records-${indexTd.toString()}`}
                      isProjection={td.type === 'projection'}
                    >
                      {td?.value === null ? '-' : tdValue(td?.value)}
                    </TableV2.Td>
                  ))}
                </TableV2.Tr>
              ))}

              {Array.from({
                length:
                  QUANTITY_ITEMS_COLUMNS_AS_NAME -
                  (dataTableToShow?.rows?.length ?? 0),
              }).map((_, index) => (
                <TableV2.Tr
                  key={`tr-empty-${index + 1}`}
                  data-testid="tr-empty"
                  height="24px"
                >
                  {Array.from({
                    length:
                      (dataTableToShow?.columns.length ?? 0) +
                      (columnsAsDate ? 1 : 0) +
                      1,
                  }).map((__, tdIndex) => (
                    <TableV2.Td
                      key={`tr-empty-${index + 1}-td-${tdIndex + 1}`}
                    />
                  ))}
                </TableV2.Tr>
              ))}
            </TableV2.Tbody>
          </TableV2.Root>
        </ContainerTable>
      )}

      {(isLoadingGroups ||
        !dataGroups ||
        isDataTableLoading ||
        !dataTableToShow) &&
      !isDataTableErrored &&
      !isGroupsErrored ? (
        <ContainerTable
          noBorderRadiusBottom
          ref={containerTableRef}
          firstThWidth={236}
          firstThHeight={48}
        >
          <TableV2.Root data-testid="data-table-loading">
            <TableV2.Thead>
              <TableV2.Tr>
                {Array.from({
                  length: 8,
                }).map((_, index) => (
                  <TableV2.Th key={`column-loading-${index + 1}`}>
                    <ContainerSkeleton
                      withLoading={false}
                      style={{ height: '24px', width: '200px' }}
                    />
                  </TableV2.Th>
                ))}
              </TableV2.Tr>
            </TableV2.Thead>
            <TableV2.Tbody>
              {Array.from({ length: 8 }).map((_, index) => (
                <TableV2.Tr key={`row-loading-${index + 1}`}>
                  {Array.from({
                    length: 8,
                  }).map((__, indexRow) => (
                    <TableV2.Td key={`${indexRow + 1}`}>
                      <ContainerSkeleton
                        withLoading={false}
                        style={{ height: '24px' }}
                      />
                    </TableV2.Td>
                  ))}
                </TableV2.Tr>
              ))}
            </TableV2.Tbody>
          </TableV2.Root>
        </ContainerTable>
      ) : (
        (isGroupsErrored ||
          dataGroups?.tabs === null ||
          isDataTableErrored) && (
          <ContainerMaintenance
            data-testid="container-data-table-error"
            content="table"
            text={translate('preDefinedUnableGroup')}
          />
        )
      )}

      {dataGroups?.tabs && !isDataTableErrored && (
        <ContainerTabs>
          {!!dataGroups.summaries.length && (
            <Tabs index="summary" text={translate('dataTableSummary')} />
          )}
          {dataGroups?.tabs.map((tab, index) => (
            <Tabs
              key={tab.title['en-us'].toLocaleLowerCase().replaceAll(' ', '-')}
              index={index.toString()}
              text={tab.title[language] ?? tab.title['en-us']}
            />
          ))}
        </ContainerTabs>
      )}

      {!user.isFreemium && <ListIndicatorsForAnalyze />}

      {failedModalVisible && (
        <FailedModal
          visible={failedModalVisible}
          setVisible={setFailedModalVisible}
          errorInfo={failedModalInfo}
        />
      )}

      {failModalVisible && (
        <FailedModal
          visible={failModalVisible}
          setVisible={setFailModalVisible}
          errorInfo={{
            title: translate('requestFailed'),
            description: translate('preDefinedExcludedError'),
          }}
        />
      )}

      <ModalLoading
        visible={downloadLoading}
        message="Downloading..."
        data-testid="download-start"
      />

      <WarningModal
        visible={summaryModalVisible}
        setVisible={(prevValue) => {
          setSummaryModalVisible(prevValue);
        }}
        errorInfo={{
          description: translate('exportSeriesMaintenance'),
        }}
      />
    </Container>
  );
};
