import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import HighchartsReact from 'highcharts-react-official';
import ms from 'ms';
import { PencilSimple } from 'phosphor-react';
import { useTranslation } from 'react-i18next';
import { useQueries, useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { Card } from 'src/components/Card';
import { ContainerSkeleton } from 'src/components/ContainerSkeleton';
import { HCharts, HChartsOptions, HChartsSeries } from 'src/components/HCharts';
import { SelectedFilters } from 'src/components/SelectedFilters';
import { SelectedFilterOptions } from 'src/components/SelectedFilters/types';
import { Status } from 'src/components/Status';
import apiWorkspace from 'src/models/service/apiWorkspace';
import { RootState } from 'src/redux/store';
import { getChartColor } from 'src/utils/colors/getChartColor';
import { formatCompactNotation } from 'src/utils/numbers/formatCompactNotation';
import { transformUppercaseFirstLetter } from 'src/utils/strings/transformUppercaseFirstLetter';
import { queryClient } from 'src/service/queryClient';

import { getSelectedFrequencyAndPeriod } from '../../utils/getSelectedFrequencyAndPeriod';
import { HierarchicalSelect } from '../HierarchicalSelect';
import { Container, SerieFiltersContainer } from './styles';
import { Data, SelectedSerie, SerieData, SerieOptions, YsInfo } from './types';
import { DiscardingAdjustmentsContainer } from '../Results/styles';
import { useQueryReleaseData } from '../../../hooks/useQueryReleaseData';

const colors = [
  getChartColor(0),
  getChartColor(1),
  getChartColor(2),
  getChartColor(3),
];

export const ResultsComparison: React.FC = () => {
  const chartRef = useRef<HighchartsReact.RefObject>(null);

  const [selectedSeries, setSelectedSeries] = useState<SelectedSerie[]>([]);

  const [levelsQtty, setLevelsQtty] = useState<number>();

  const [hasChartZoom, setHasChartZoom] = useState(false);

  const [chartColorsUsed, setChartColorsUsed] = useState<string[]>([]);

  const [filteredData, setFilteredData] = useState<SerieData[]>([]);

  const [filteredDataEmpty, setFilteredDataEmpty] = useState(false);

  const [isDiscardingAdjustments, setIsDiscardingAdjustments] = useState(false);

  const {
    auth: {
      user: { language },
    },
    workspace: {
      id: workspaceId,
      releaseSelected,
      frequency: workspaceFrequency,
      releasePreview,
    },
    workspaceOverviewOptions: {
      frequency,
      transformation,
      forecast,
      period,
      inflation,
      step,
    },
  } = useSelector((state: RootState) => state);

  const { data: releaseData, isLoading: releaseIsLoading } =
    useQueryReleaseData(workspaceId, releaseSelected?.id);

  const { t: translate } = useTranslation();

  const checkHasZoom = () => {
    const zoomButton = document.getElementsByClassName('highcharts-reset-zoom');

    if (zoomButton.length) {
      setHasChartZoom(true);
    } else {
      setHasChartZoom(false);
    }
  };

  const { data: ysInfoData } = useQuery(
    ['workspace', 'ys info', workspaceId, releaseSelected?.id],
    async () => {
      const { data } = await apiWorkspace.get<YsInfo>(
        `workspaces/${workspaceId}/releases/${releaseSelected?.id}/ys`,
      );

      return data;
    },
    {
      enabled: !!workspaceId && !!releaseSelected?.id,
      staleTime: ms('1 day'),
    },
  );

  const handleSerieRemoved = (serie: SelectedSerie) => {
    checkHasZoom();

    const serieRemovedIndex = selectedSeries.findIndex(
      ({ value }) => value.toString() === serie.value.toString(),
    );

    if (serieRemovedIndex !== -1) {
      setChartColorsUsed(
        chartColorsUsed.filter(
          (color) => color !== selectedSeries[serieRemovedIndex].color,
        ),
      );

      selectedSeries.splice(serieRemovedIndex, 1);
    }
  };

  const handleSerieAdded = (serie: SelectedSerie) => {
    checkHasZoom();

    const color =
      colors.find((option) => !chartColorsUsed.includes(option ?? '')) ?? '';

    setChartColorsUsed([...chartColorsUsed, color]);

    setSelectedSeries([...selectedSeries, { ...serie, color }]);
  };

  const serieOptions: SerieOptions[] = useMemo(() => {
    const options: SerieOptions[] = [];

    if (ysInfoData) {
      ysInfoData.ys.forEach((y) => {
        let currentLevelOptions = options;

        let level = 1;

        if (ysInfoData.market_share.enable) {
          const yType =
            y.type === 'marketsize'
              ? 'Market Size'
              : y.type === 'sellout'
              ? 'Sell Out'
              : y.type === 'others'
              ? translate('workspaceOverviewOthers')
              : y.type;

          const existsOption = currentLevelOptions.find(
            (option) => option.name === yType,
          );

          if (existsOption) {
            currentLevelOptions = existsOption.children ?? [];
          } else {
            const newOption = {
              name: yType,
              level: 1,
              children: [],
              canSelect: false,
            };

            currentLevelOptions.push(newOption);
            currentLevelOptions = newOption.children;
          }

          level++;
        }

        y.hierarchy.forEach((filter, index) => {
          const existsOption = currentLevelOptions.find(
            (option) => option.name === filter.value,
          );

          if (existsOption) {
            currentLevelOptions = existsOption.children ?? [];
          } else {
            const canSelect = ysInfoData.aggregation?.enable;

            const newOption = {
              name: filter.value,
              level: index + (ysInfoData.market_share.enable ? 2 : 1),
              children: [],
              canSelect,
            };

            currentLevelOptions.push(newOption);
            currentLevelOptions = newOption.children;
          }

          level++;
        });

        currentLevelOptions.push({
          name: y.y_label,
          level,
          children: [],
          canSelect: true,
        });

        if (!levelsQtty) {
          setLevelsQtty(level);
        }
      });

      // if (
      //   ysInfoData.market_share.enable &&
      //   ysInfoData.series.length &&
      //   ysInfoData.series[0].name === 'marketshare'
      // ) {
      //   let level = 1;

      //   const marketShareOption: SerieOptions = {
      //     name: 'Market Share (%)',
      //     children: [],
      //     level,
      //     canSelect: false,
      //   };

      //   let currentLevelOptions = marketShareOption.children;

      //   ysInfoData.series[0].hierarchies.forEach((hierarchies) => {
      //     level = 2;
      //     currentLevelOptions = marketShareOption.children;
      //     hierarchies.forEach((hierarchy, index) => {
      //       const existsOption = currentLevelOptions.find(
      //         (option) => option.name === hierarchy.value,
      //       );

      //       if (existsOption) {
      //         currentLevelOptions = existsOption.children ?? [];
      //       } else {
      //         const canSelect =
      //           index === hierarchies.length - 1 ||
      //           ysInfoData.aggregation?.enable;

      //         const newOption = {
      //           name: hierarchy.value,
      //           level: index + (ysInfoData.market_share.enable ? 2 : 1),
      //           children: [],
      //           canSelect,
      //         };

      //         currentLevelOptions.push(newOption);
      //         currentLevelOptions = newOption.children;
      //       }

      //       level++;
      //     });
      //   });

      //   options.push(marketShareOption);
      // }
    }

    return options;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ysInfoData]);

  const ys: string[] = useMemo(() => {
    if (ysInfoData) {
      return ysInfoData.ys.map((y) => y.y_label);
    }
    return [];
  }, [ysInfoData]);

  const isYSerie = useCallback(
    (label: string, value: string[]) =>
      value?.length === levelsQtty && ys.includes(label),
    [levelsQtty, ys],
  );

  const chartData = useQueries(
    selectedSeries
      .filter((serie) => serie.label)
      .map((serie) => {
        const isY = isYSerie(serie.label, serie.value);

        const isMarketShare = serie.value.includes('Market Share (%)');

        let hierarchies = [...serie.value];

        if (ysInfoData?.market_share.enable) {
          const types = [
            'Market Size',
            'Sell Out',
            'Market Share (%)',
            translate('workspaceOverviewOthers'),
          ];

          hierarchies = hierarchies.filter(
            (hierarchy) => !types.includes(hierarchy),
          );
        }

        const queryKey = [
          'workspace',
          'series data chart',
          workspaceId,
          releaseSelected?.id,
          isY ? serie.label : hierarchies.toString().replaceAll(',', ' '),
          forecast,
          step?.number ?? 1,
          frequency,
          transformation,
          inflation,
        ];

        let serieType = serie.value[0].toLowerCase().replaceAll(' ', '');

        if (serieType === 'outros') {
          serieType = 'others';
        }

        if (isMarketShare) {
          queryKey.push('marketshare');
        } else if (ysInfoData?.market_share.enable) {
          queryKey.push(serieType);
        }

        const queryFn = async () => {
          const isYInflate = releaseSelected?.data.ys.find(
            (y) => y.y_label === serie.label,
          )?.is_inflated;

          let inflationAux: 'inflate' | 'original' = 'inflate';

          if (
            inflation === 'real' ||
            (typeof isYInflate === 'boolean' && isYInflate === false)
          ) {
            inflationAux = 'original';
          }

          let route = '';

          if (isY) {
            route = `/workspaces/${workspaceId}/releases/${
              releaseSelected?.id
            }/series/raw/${
              serie.label
            }?frequency=${frequency}&transformation=${transformation}&inflation=${inflationAux}&data_type=${forecast}&step=${
              step?.number ?? 1
            }`;
          } else {
            let hierarchiesQuery = '';

            hierarchies.forEach((hierarchy) => {
              hierarchiesQuery = hierarchiesQuery.concat(
                `hierarchy=${encodeURI(hierarchy)}&`,
              );
            });

            if (isMarketShare) {
              route = `/workspaces/${workspaceId}/releases/${
                releaseSelected?.id
              }/series/calculated?calc=marketshare&${hierarchiesQuery}frequency=${frequency}&transformation=${transformation}&inflation=${inflationAux}&data_type=${forecast}&step=${
                step?.number ?? 1
              }`;
            } else {
              const typeAux = ysInfoData?.market_share.enable
                ? encodeURI(`filter=y_type=${serieType}&`)
                : '';
              route = `/workspaces/${workspaceId}/releases/${
                releaseSelected?.id
              }/series/calculated?calc=sum&${hierarchiesQuery}${typeAux}frequency=${frequency}&transformation=${transformation}&inflation=${inflationAux}&data_type=${forecast}&step=${
                step?.number ?? 1
              }`;
            }
          }

          const { data } = await apiWorkspace.get<SerieData>(route);

          const historical = data.historical;
          const forecastData = data.forecast;

          return {
            serie: isY ? serie.label : serie.value.toString(),
            historical,
            forecast: forecastData,
          };
        };

        return {
          queryKey,
          queryFn,
          staleTime: 1000 * 60 * 20,
        };
      }),
  );

  const { loadedSeries, seriesWithError } = useMemo(() => {
    const loaded: SerieData[] = [];
    const withError: string[] = [];

    const formatForecastData = (historicalData: Data, forecastData?: Data) => {
      const forecastDataUpdated = {
        dates: [...(forecastData?.dates ?? [])],
        values: [...(forecastData?.values ?? [])],
      };

      if (forecastData?.dates.length && forecastData.values.length) {
        if (historicalData?.dates.length && historicalData.dates.length) {
          forecastDataUpdated.dates = [
            historicalData.dates[historicalData.dates.length - 1],
            ...forecastData.dates,
          ];

          forecastDataUpdated.values = [
            historicalData.values[historicalData.values.length - 1],
            ...forecastData.values,
          ];
        }
      }

      return forecastDataUpdated;
    };

    chartData.forEach((res, index) => {
      if (!!res.data && !res.isLoading && !res.isFetching && !res.isError) {
        const historical = res.data.historical;
        const forecastData = formatForecastData(historical, res.data.forecast);

        loaded.push({ ...res.data, historical, forecast: forecastData });
      }

      if (res.isError) {
        withError.push(selectedSeries[index].label);
      }
    });

    return {
      loadedSeries: loaded,
      seriesWithError: withError,
    };
  }, [chartData, selectedSeries]);

  useEffect(() => {
    const filterDataInterval = (data: Data) => {
      const newData: Data = { dates: [], values: [] };

      data.dates.forEach((date, index) => {
        const formattedDate = new Date(date.replace(':00Z', ''));

        if (
          !!period[0] &&
          !!period[1] &&
          formattedDate >= period[0] &&
          formattedDate <= period[1]
        ) {
          newData.dates.push(date);
          newData.values.push(data.values[index]);
        } else if (!period[0] || !period[1]) {
          newData.dates.push(date);
          newData.values.push(data.values[index]);
        }
      });

      return newData;
    };

    if (!!period[0] && !!period[1]) {
      let dataEmpty = true;

      const updatedFilteredData: SerieData[] = [];

      loadedSeries.forEach((data) => {
        const historical = filterDataInterval(data.historical);
        const forecastData = filterDataInterval(
          data.forecast ?? { dates: [], values: [] },
        );

        updatedFilteredData.push({
          ...data,
          historical,
          forecast: forecastData,
        });

        if (historical.dates.length || forecastData.dates.length) {
          dataEmpty = false;
        }
      });

      setFilteredData(updatedFilteredData);

      setFilteredDataEmpty(dataEmpty);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(period), JSON.stringify(loadedSeries)]);

  const hChartsSeries: HChartsSeries[] = useMemo(
    () =>
      filteredData.flatMap((seriesChart, index) => [
        {
          type: 'line',
          name: seriesChart.serie.replaceAll(',', '  ->  '),
          color: selectedSeries.find(({ label }) => label === seriesChart.serie)
            ?.color,
          dashStyle: 'Solid',
          data: seriesChart.historical.dates.map((date, dateIndex) => ({
            x: new Date(date.replace(':00Z', '')).getTime(),
            y: seriesChart.historical.values[dateIndex],
            custom: {
              value: formatCompactNotation(
                seriesChart.historical.values[dateIndex],
              ),
              keyValue: translate('Historical'),
            },
          })),
          id: String(index),
          marker: {
            enabledThreshold: 2,
            radius: 4,
            symbol: 'circle',
          },
        },
        {
          type: 'line',
          name: seriesChart.serie.replaceAll(',', '  ->  '),
          color: selectedSeries.find(({ label }) => label === seriesChart.serie)
            ?.color,
          dashStyle: 'Dash',
          data:
            seriesChart.forecast?.dates.map((date, dateIndex) => ({
              x: new Date(date.replace(':00Z', '')).getTime(),
              y: seriesChart.forecast?.values[dateIndex],
              custom: {
                value: formatCompactNotation(
                  seriesChart.forecast?.values[dateIndex] ?? 0,
                ),
                keyValue:
                  seriesChart.historical.dates.length && dateIndex === 0
                    ? translate('Historical')
                    : translate('Forecast'),
              },
            })) ?? [],
          linkedTo: String(index),
          marker: {
            enabledThreshold: 2,
            radius: 4,
            symbol: 'circle',
          },
        },
      ]),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [translate, filteredData],
  );

  const hChartsOptions: HChartsOptions = useMemo(
    () => ({
      chart: {
        height: 500,
      },
      tooltip: {
        pointFormat:
          `<tr><td><b>${translate('date')}:</b> </td>` +
          `<td style="text-align: right">{point.x: %d/%m/%Y}</td></tr>` +
          `<tr><td><b>{point.custom.keyValue}:</b> </td>` +
          '<td style="text-align: right">{point.custom.value}</td></tr>',
      },
      navigator: {
        enabled: true,
        xAxis: {
          gridLineWidth: 0,
        },
      },
    }),
    [translate],
  );

  useEffect(() => {
    if (chartRef) {
      const chart = chartRef.current?.chart;

      if (chart) {
        const { min, max } = chart.xAxis[0].getExtremes();

        chart.zoomOut();

        if (hasChartZoom) {
          chart.showResetZoom();

          chart.xAxis[0].setExtremes(min, max, true, false);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredData]);

  useEffect(() => {
    if (!selectedSeries.length) {
      setChartColorsUsed([]);
    }
  }, [selectedSeries]);

  useEffect(() => {
    const isRemovingFirstStep =
      releaseData?.status === 'removing_step' &&
      releaseData?.data?.steps?.[0]?.status === 'baseline';

    if (
      (isDiscardingAdjustments ||
        releaseData?.status === 'discarding_step' ||
        isRemovingFirstStep) &&
      !releaseIsLoading
    ) {
      if (releaseData?.status === 'discarding_step' || isRemovingFirstStep) {
        setIsDiscardingAdjustments(true);
      } else {
        queryClient.refetchQueries(['workspace', 'series data chart']);
        setIsDiscardingAdjustments(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDiscardingAdjustments, releaseIsLoading, releaseData?.status]);

  const selectedFilterInflation =
    language === 'pt-br'
      ? `${translate('value')} ${inflation}`
      : `${transformUppercaseFirstLetter(inflation)} ${translate(
          'value',
        ).toLowerCase()}`;

  const selectedFilters: SelectedFilterOptions[] = [
    {
      type: 'frequency',
      selected: getSelectedFrequencyAndPeriod(
        translate(frequency),
        period,
        language,
      ),
    },
    { type: 'transformation', selected: translate(transformation) },
  ];

  const hasForecastLabel =
    workspaceFrequency === 'monthly' &&
    !releaseData?.data.approval_flow?.enable &&
    releaseSelected?.id !== releasePreview;

  hasForecastLabel &&
    selectedFilters.push({
      type: 'other',
      id: 'forecast',
      icon: <PencilSimple />,
      selected: translate(
        forecast === 'adjusted' ? 'workspaceProjectionsMostRecent' : forecast,
      ),
    });

  const hasInflationLabel = releaseSelected?.data.ys.some((y) => y.is_inflated);

  hasInflationLabel &&
    selectedFilters.push({
      type: 'inflation',
      selected: selectedFilterInflation,
    });

  return (
    <Container
      data-testid="results-comparison-description-card"
      className="containerLinear"
    >
      <Card
        textCard={translate('workspaceOverviewResultsComparisonTitle')}
        textDescription={translate(
          'workspaceOverviewResultsComparisonDescription',
        )}
      />

      <SelectedFilters filters={selectedFilters} />

      <SerieFiltersContainer>
        <HierarchicalSelect
          label={translate('workspaceOverviewResultsComparisonSerieLabel')}
          placeholder={translate(
            'workspaceOverviewResultsComparisonSeriePlaceholder',
          )}
          options={serieOptions}
          selected={selectedSeries}
          setSelected={setSelectedSeries}
          loadingSelectedData={
            selectedSeries.length > 1 &&
            !!filteredData.length &&
            filteredData.length + seriesWithError.length !==
              selectedSeries.length
          }
          loadingOptions={!serieOptions.length}
          optionRemoved={handleSerieRemoved}
          optionAdded={handleSerieAdded}
          maxNumberOptionsSelected={4}
          data-testid={
            !serieOptions.length
              ? 'loading-select-options'
              : 'hierarchical-select-serie'
          }
        />
      </SerieFiltersContainer>

      {isDiscardingAdjustments &&
      step?.number === releaseData?.data.steps?.length ? (
        <DiscardingAdjustmentsContainer data-testid="loading-discarding-adjustments">
          <ContainerSkeleton style={{ height: 'fit-content' }} />

          <p>
            {translate('workspaceOverviewPlanningFlowDiscardingAdjustments')}
          </p>
        </DiscardingAdjustmentsContainer>
      ) : !selectedSeries.length ? (
        <Status
          type="selectLineChartFilter"
          title={translate(
            'workspaceOverviewResultsComparisonSelectFilterTitle',
          )}
          description={translate(
            'workspaceOverviewResultsComparisonSelectFilterDescription',
          )}
        />
      ) : seriesWithError.length ? (
        <Status
          type="error"
          title={translate('workspaceOverviewResultsComparisonErrorTitle')}
          description={translate(
            'workspaceOverviewResultsComparisonErrorDescription',
          )}
        />
      ) : !filteredData.length ? (
        <ContainerSkeleton
          data-testid="loading-results-comparison"
          style={{ height: '22rem' }}
        />
      ) : filteredDataEmpty ? (
        <Status
          type="error"
          description={translate(
            'workspaceOverviewResultsComparisonEmptyDataDescription',
          )}
        />
      ) : (
        <HCharts
          ref={chartRef}
          series={hChartsSeries}
          options={hChartsOptions}
          dataCy="results-comparison-chart"
          zoomOutAfterChangingSeries={false}
          resizeWidthWithSidebar
        />
      )}
    </Container>
  );
};
