import React, { useEffect, useRef, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { Card } from 'src/components/Card';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/redux/store';
import { ContainerSkeleton } from 'src/components/ContainerSkeleton';
import { queryClient } from 'src/service/queryClient';
import { sleep } from 'src/utils/sleep';
import { Button } from 'src/components/Button';
import apiWorkspace from 'src/models/service/apiWorkspace';
import ms from 'ms';
import { ClockCounterClockwise, Plus } from 'phosphor-react';
import { ButtonRounded } from 'src/components/ButtonRounded';
import { Tooltip } from 'react-tooltip';
import {
  changeShowChangeHistory,
  changeWorkspaceOverviewStep,
} from 'src/models/redux/reducers/WorkspaceOverviewOptions';

import { Step } from './Step';
import { useQueryReleaseData } from '../../../hooks/useQueryReleaseData';
import { BlueLine, Line } from './Step/styles';
import { Container, LatestStepContainer, StepsContainer } from './styles';
import { State } from './Step/types';

export const PlanningFlow: React.FC = () => {
  const [loadingNewStep, setLoadingNewStep] = useState(false);
  const [isCreatingNewStep, setIsCreatingNewStep] = useState(false);
  const [newStepNumber, setNewStepNumber] = useState<number>();

  const [isRemovingStep, setIsRemovingStep] = useState(false);

  const [userCreatedNextStep, setUserCreatedNextStep] = useState(false);

  const stepsContainer = useRef<HTMLDivElement>(null);

  const { t: translate } = useTranslation();
  const dispatch = useDispatch();

  const {
    workspace,
    workspaceOverviewOptions: { step: currentStep, showChangeHistory },
  } = useSelector((state: RootState) => state);

  const { data: releaseData, isLoading: releaseIsLoading } =
    useQueryReleaseData(workspace.id, workspace.releaseSelected?.id);

  const stepsQtty = releaseData?.data.steps
    ? releaseData?.data.steps.length
    : 0;

  const scrollToEnd = () => {
    const container = stepsContainer.current;

    if (container) {
      container.scrollLeft = container.scrollWidth;
    }
  };

  const getCorrectStepStatus = (stepNumber: number, status: State) =>
    stepNumber !== 1 && status === 'baseline' ? 'adjusting' : status;

  const updateStepStatusToAdjusting = async (stepNumber: number) => {
    try {
      await apiWorkspace.put(
        `/workspaces/${workspace.id}/releases/${workspace.releaseSelected?.id}/steps/${stepNumber}`,
        { status: 'adjusting' },
      );
      // eslint-disable-next-line no-empty
    } catch {}

    await queryClient.refetchQueries({
      queryKey: [
        'workspace',
        workspace.id,
        'releases',
        workspace.releaseSelected?.id,
      ],
      exact: true,
    });

    await queryClient.refetchQueries({
      queryKey: [
        'workspace',
        workspace.id,
        'releases',
        workspace.releaseSelected?.id,
        'logs',
        stepNumber,
      ],
    });
  };

  const updateSelectedStepToLatestStep = async () => {
    if (releaseData && releaseData.data.steps) {
      let stepNumber = stepsQtty;

      if (releaseData.status === 'creating_step') {
        stepNumber = stepsQtty - 1;
      }

      const lastStep = releaseData.data.steps[stepNumber - 1];

      const status = getCorrectStepStatus(stepNumber, lastStep.status);

      queryClient.removeQueries([
        'workspace',
        workspace.id,
        'releases',
        workspace?.releaseSelected?.id,
        'logs',
        stepNumber,
      ]);

      dispatch(
        changeWorkspaceOverviewStep({
          number: stepNumber,
          name: lastStep.name,
          status,
          approval_messages: lastStep.approval_messages ?? [],
          awaiting_approval_messages: lastStep.awaiting_approval_messages ?? [],
          disapproval_messages: lastStep.disapproval_messages ?? [],
          selectedStatus: status,
        }),
      );

      if (status !== lastStep.status) {
        await updateStepStatusToAdjusting(stepNumber);
      }
    }
  };

  const updateSelectedStepStatus = () => {
    const allSteps = releaseData?.data.steps;

    const currentStepNumber = currentStep?.number;

    if (currentStepNumber && allSteps?.length) {
      if (currentStepNumber <= allSteps.length) {
        const updatedStep = allSteps[currentStepNumber - 1];

        dispatch(
          changeWorkspaceOverviewStep({
            ...updatedStep,
            number: currentStepNumber,
            selectedStatus: updatedStep.status,
          }),
        );
      } else {
        updateSelectedStepToLatestStep();
      }
    }
  };

  const handleAddNewStep = async () => {
    setLoadingNewStep(true);

    try {
      const stepNumber = stepsQtty + 1;
      setNewStepNumber(stepNumber);

      const stepName = `${translate(
        'workspaceOverviewPlanningFlowStep',
      )}${stepNumber}`;

      await apiWorkspace.post(
        `/workspaces/${workspace.id}/releases/${workspace.releaseSelected?.id}/steps`,
        {
          name: stepName,
        },
      );

      setUserCreatedNextStep(true);
      setIsCreatingNewStep(true);

      await queryClient.refetchQueries({
        queryKey: [
          'workspace',
          workspace.id,
          'releases',
          workspace.releaseSelected?.id,
        ],
        exact: true,
      });

      setLoadingNewStep(false);

      await queryClient.refetchQueries({
        queryKey: ['workspace', 'logs'],
      });

      // eslint-disable-next-line no-empty
    } catch {}
  };

  useEffect(() => {
    if (!currentStep) {
      updateSelectedStepToLatestStep();
    } else {
      updateSelectedStepStatus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [releaseData, dispatch, stepsQtty]);

  async function handleChangeHistory() {
    dispatch(changeShowChangeHistory(true));
  }

  useEffect(() => {
    if (releaseData?.data.steps?.length) {
      setNewStepNumber(releaseData.data.steps.length);
    }
  }, [releaseData]);

  useEffect(() => {
    scrollToEnd();
  }, [loadingNewStep, isCreatingNewStep, releaseData?.data.steps?.length]);

  useEffect(() => {
    const checkIfStatusHasBeenUpdated = async () => {
      await sleep(ms('15s'));

      queryClient.refetchQueries({
        queryKey: [
          'workspace',
          workspace.id,
          'releases',
          workspace.releaseSelected?.id,
        ],
        exact: true,
      });
    };

    if (!releaseIsLoading) {
      if (releaseData?.status === 'creating_step') {
        setIsCreatingNewStep(true);
        checkIfStatusHasBeenUpdated();
      } else if (releaseData?.status === 'removing_step') {
        setIsRemovingStep(true);
        checkIfStatusHasBeenUpdated();
      } else if (isCreatingNewStep) {
        setIsCreatingNewStep(false);

        if (userCreatedNextStep) {
          updateSelectedStepToLatestStep();
        }
      } else if (isRemovingStep) {
        setIsRemovingStep(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isCreatingNewStep,
    releaseIsLoading,
    releaseData?.status,
    workspace.id,
    workspace.releaseSelected?.id,
    isRemovingStep,
  ]);

  useEffect(() => {
    queryClient.invalidateQueries({
      queryKey: [
        'workspace',
        'results',
        workspace.id,
        workspace.releaseSelected?.id,
      ],
    });
    queryClient.invalidateQueries({
      queryKey: [
        'workspace',
        'projections',
        'series data',
        workspace.id,
        workspace.releaseSelected?.id,
      ],
    });
  }, [workspace.id, workspace.releaseSelected?.id]);

  const canShowButtonToAddNewStep = workspace.userRole === 'manager';

  const lastStepWasApproved =
    releaseData?.data.steps?.[stepsQtty - 1].status === 'approved';

  const canAddNewStep =
    lastStepWasApproved && stepsQtty < 5 && !loadingNewStep && !isRemovingStep;

  return (
    <Container className="containerLinear">
      <Card
        textCard={translate('workspaceOverviewPlanningFlowTitle')}
        textDescription={translate('workspaceOverviewPlanningFlowDescription')}
      />

      <LatestStepContainer>
        <Button
          buttonType="secondary"
          data-testid="button-open-change-history"
          disabled={showChangeHistory}
          onClick={handleChangeHistory}
        >
          <ClockCounterClockwise size={16} />
          {translate('workspaceOverviewLogsButton')}
        </Button>
      </LatestStepContainer>

      {!releaseData && releaseIsLoading ? (
        <ContainerSkeleton
          withLoading={false}
          data-testid="container-loading-release-data"
          style={{ height: '8.938rem' }}
        />
      ) : (
        <StepsContainer ref={stepsContainer} data-testid="container-steps">
          {releaseData?.data.steps?.map((step, index) => {
            const stepNumber = index + 1;

            const isSelectedStep = currentStep?.number === stepNumber;

            const updatedStep = isSelectedStep ? currentStep : step;
            const isFirstStep = index === 0;
            const nextStep =
              stepNumber + 1 === currentStep?.number
                ? currentStep
                : releaseData.data.steps?.[index + 1];

            const isLastStep = index === stepsQtty - 1;

            const status = getCorrectStepStatus(stepNumber, updatedStep.status);

            return (
              <React.Fragment key={step.created}>
                {!isFirstStep && (
                  <Line
                    style={{
                      minWidth: '1.5rem',
                      maxWidth: '1.5rem',
                      marginTop: '1.4rem',
                    }}
                  >
                    <BlueLine show />
                  </Line>
                )}
                <Step
                  id={String(stepNumber)}
                  name={step.name.replace(
                    /\b(Step|Stage|Etapa)\s/g,
                    translate('workspaceOverviewPlanningFlowStep'),
                  )}
                  isFirstStep={isFirstStep}
                  isLastStep={isLastStep}
                  isSelectedStep={isSelectedStep}
                  currentState={status}
                  canHaveNextStep={index !== 4}
                  nextStepState={nextStep?.status}
                  isCreatingStep={
                    isCreatingNewStep && stepNumber === newStepNumber
                  }
                  isLoadingNewStep={loadingNewStep || isCreatingNewStep}
                  isRemovingStep={isRemovingStep}
                  messages={{
                    approval_messages: step.approval_messages,
                    awaiting_approval_messages: step.awaiting_approval_messages,
                    disapproval_messages: step.disapproval_messages,
                  }}
                />
              </React.Fragment>
            );
          })}

          {loadingNewStep && (
            <ContainerSkeleton
              withLoading={false}
              data-testid="container-loading-new-step"
              style={{
                minWidth: '19.375rem',
                maxWidth: '19.375rem',
                height: '8.438rem',
                marginLeft: '1.5rem',
                marginTop: '0.7rem',
              }}
            />
          )}

          {canShowButtonToAddNewStep && (
            <ButtonRounded
              icon={<Plus />}
              onClick={handleAddNewStep}
              disabled={!canAddNewStep}
              data-testid="button-add-new-step"
              data-tooltip-id="planning-flow"
              data-tooltip-content={
                stepsQtty === 5
                  ? translate(
                      'workspaceOverviewPlanningFlowNumberMaxOfStepsTooltip',
                    )
                  : !lastStepWasApproved
                  ? translate(
                      'workspaceOverviewPlanningFlowApproveLastStepTooltip',
                    )
                  : isRemovingStep
                  ? translate('workspaceOverviewPlanningFlowIsRemoving')
                  : undefined
              }
            />
          )}
        </StepsContainer>
      )}

      <Tooltip id="planning-flow" className="customTooltipTheme" />
    </Container>
  );
};
