import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  margin-left: 5rem;
  margin-right: 5rem;

  @media (max-width: 1550px) {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: stretch;

  gap: 1rem;

  h4 {
    color: ${({ theme }) => theme.colors.gray6};
    font-family: Inter;
    font-size: 2rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
`;

export const ActionButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: center;

  gap: 0.5rem;
`;

export const ActionButton = styled.button`
  display: flex;
  padding: 0.5rem 0.75rem;
  align-items: flex-start;
  gap: 0.5rem;

  border-radius: 624.9375rem;
  border: 1px solid ${({ theme }) => theme.colors.gray2};

  color: ${({ theme }) => theme.colors.gray5};

  font-family: Inter;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  svg {
    color: ${({ theme }) => theme.colors.gray4};

    width: 1rem;
    height: 1rem;
  }

  :hover {
    color: ${({ theme }) => theme.colors.gray6};

    svg {
      color: ${({ theme }) => theme.colors.gray5};
    }
  }
`;

export const CardFeatures = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  svg {
    color: ${({ theme }) => theme.colors.gray4};
    margin-right: 1.5rem;
    cursor: pointer;
    transition: 0.2s color;

    &:hover {
      color: ${({ theme }) => theme.colors.gray5};
    }
  }

  .cardDescription {
    max-width: 61.25rem;
    p {
      word-break: break-word;
    }
  }
`;

export const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 87% 13%;
  grid-column-gap: 2rem;

  @media (min-width: 1080px) {
    grid-template-columns: 82% 18%;
    grid-column-gap: 1rem;
  }
`;

export const ChartContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SelectContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: stretch;

  margin: 1.875rem 0;
  gap: 1.5rem;
`;

export const HeaderChart = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-content: stretch;
  align-items: end;

  h4 {
    color: ${({ theme }) => theme.colors.gray6};

    font-family: Inter;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
`;

export const TypeChartContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: stretch;
  align-content: stretch;

  gap: 0.5rem;
`;

export const ChartContent = styled.div`
  position: relative;

  margin-top: 1.875rem;

  .highcharts-axis-labels.highcharts-xaxis-labels.highcharts-navigator-xaxis {
    display: none;
  }

  .highcharts-tooltip {
    p + p {
      margin-top: 0.25rem;
    }

    b {
      font-weight: 600;
    }
  }

  .highcharts-navigator-outline {
    stroke: ${({ theme }) => theme.colors.gray1};
  }
`;

export const ContainerErrorChart = styled.div`
  padding: 1.25rem;
`;
