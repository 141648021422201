import React, { useEffect, useRef } from 'react';

import { AgGridReact } from 'ag-grid-react';
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
import { ServerSideRowModelModule } from '@ag-grid-enterprise/server-side-row-model';
import { useTranslation } from 'react-i18next';
import 'ag-grid-enterprise';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';

import { AgGridTableProps } from './types';
import { Container } from './styles';

export const AgGridTable: React.FC<AgGridTableProps> = ({
  tableRef,
  rowData,
  columnDefs,
  checkDomLayoutType = false,
  maxRemHeight,
  ...props
}) => {
  const { t: translate } = useTranslation();

  const agGridRef = useRef<AgGridReact>(null);

  const localeText = {
    // Textos dos filtros
    selectAll: translate('agGridTableSelectAll'),
    searchOoo: translate('agGridTableSearch'),
    noMatches: translate('agGridTableNoMatches'),

    // Textos do menu quando clica com o botão direito no body da tabela
    copy: translate('agGridTableCopy'),
  };

  const defineDomLayoutType = () => {
    const tableAgRef = tableRef ?? agGridRef;

    const tableContainer = document.getElementById('ag-grid-table-container');

    const pinnedLeftColumn = document.getElementsByClassName(
      'ag-pinned-left-cols-container',
    )?.[0];

    if (tableContainer && pinnedLeftColumn && tableAgRef.current) {
      const height = pinnedLeftColumn?.clientHeight;

      const windowHeight = window.innerHeight;

      const maxHeight = maxRemHeight || (windowHeight > 800 ? 45 : 30);

      if (height && height > maxHeight * 16 - 84) {
        tableContainer.style.height = `${maxHeight}rem`;
        tableRef?.current?.api.setGridOption('domLayout', 'normal');
      } else {
        tableContainer.style.height = 'fit-content';
        tableRef?.current?.api.setGridOption('domLayout', 'autoHeight');
      }
    }
  };

  useEffect(() => {
    if (checkDomLayoutType) {
      defineDomLayoutType();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkDomLayoutType, rowData.length]);

  return (
    <Container
      id="ag-grid-table-container"
      data-testid="ag-grid-table"
      className="ag-theme-quartz"
    >
      <AgGridReact
        ref={tableRef ?? agGridRef}
        rowData={rowData}
        columnDefs={columnDefs}
        stopEditingWhenCellsLoseFocus
        singleClickEdit
        enableRangeHandle
        enableRangeSelection
        suppressMenuHide
        suppressMovableColumns
        suppressAggFilteredOnly
        suppressAggFuncInHeader
        getContextMenuItems={() => ['copy']}
        icons={{
          menu: '<span class="ag-icon-filter"/>',
          sortAscending: '<i class="fa fa-caret-down"/>',
          sortDescending: '<i class="fa fa-caret-up"/>',
          sortUnSort: '<i class="fa fa-sort"/>',
        }}
        headerHeight={42}
        rowHeight={44}
        onRowGroupOpened={defineDomLayoutType}
        modules={[RowGroupingModule, ServerSideRowModelModule]}
        localeText={localeText}
        {...props}
      />
    </Container>
  );
};
